import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CryptoPanicWidget = () => {
	const { i18n, t } = useTranslation();
	const [currentLang, setCurrentLang] = useState(
		JSON.parse(localStorage.getItem("lang"))
	);

	const getData = () => {
		const script = document.createElement("script");
		script.src = "https://static.cryptopanic.com/static/js/widgets.min.js";
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	};

	useEffect(() => {
		// window.location.reload();
	}, [currentLang]);

	useEffect(() => {
		const langFromLocalStorage = JSON.parse(localStorage.getItem("lang"));
		setCurrentLang(langFromLocalStorage);
		getData();
	}, []);

	return (
		<>
			<div className="container my-4">
				<a
					href="https://cryptopanic.com/"
					target="_blank"
					data-news_feed="recent"
					data-bg_color="#FFFFFF"
					data-text_color="#100D28"
					data-link_color="#100D28"
					data-header_bg_color="#100D28"
					data-header_text_color="#FFFFFF"
					data-posts_limit="10"
					className="CryptoPanicWidget"
				>
					{t("latestNews")}
				</a>
			</div>
		</>
	);
};

export default CryptoPanicWidget;
