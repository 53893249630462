import React, { useState, useEffect } from 'react'
import { LANGUAGES } from "../constants";
import Ios from "../assets/img/ios.png";
import Android from "../assets/img/android.png";
import LogoPng from "../assets/img/logo-png.png";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import TwoFACard from '../components/2FA/TwoFACard';

const TwoFA = () => {
    const { i18n, t } = useTranslation()

    const [selectedLanguage, setSelectedLanguage] = useState(
        JSON.parse(localStorage.getItem("lang"))
            ? JSON.parse(localStorage.getItem("lang"))
            : "en"
    );

    const onChangeLang = (e) => {
        const lang_code = e.code;
        i18n.changeLanguage(lang_code);
        setSelectedLanguage(e.code);
    };

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, []);

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, [selectedLanguage]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "none",
            border: "none",
            color: "#fff",
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "none",
            color: "#fff",
            width: "150px",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "#f0f0f0" : "white",
            color: "#000",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "rgb(148 163 184/1)",
        }),
        indicatorSeparator: (provided, state) => ({
            display: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            display: "none",
        }),
    };

    return (
        <>
            <div className="bg-primary-v2 border-bottom-gray">
                <div className="container border-bottom-gray py-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Link to="/">
                                <img src={LogoPng} alt="logo" width="100px" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center navbar-end">
                            {/* <div className="me-4">
								<i className="fa-brands fa-whatsapp me-2 fa-xl wp-icon"></i>
							</div> */}
                            <Select
                                className="language-select-box"
                                defaultValue={
                                    LANGUAGES.find((lang) => lang.code === selectedLanguage)
                                        ? LANGUAGES.find((lang) => lang.code === selectedLanguage)
                                        : LANGUAGES.find((lang) => lang.code === "en")
                                }
                                // value={LANGUAGES.find((lang) => lang.code === selectedLanguage)}
                                onChange={onChangeLang}
                                styles={customStyles}
                                isSearchable={false}
                                options={LANGUAGES}
                                getOptionLabel={(option) => (
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={option.flag}
                                            alt={option.label}
                                            width="30px"
                                            className="me-2"
                                        />
                                        {option.label}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <TwoFACard />
            </div>
        </>
    )
}

export default TwoFA;