import React from "react";
import Header from "../components/Header/Header";
import CryptoPanicWidget from "../components/NewsFeed/CryptoPanicWidget";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const NewsFeed = () => {
	const { i18n, t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>Cloudex Crypto - {t("newsFeed")}</title>
			</Helmet>
			<Header />
			<CryptoPanicWidget />
		</>
	);
};

export default NewsFeed;
