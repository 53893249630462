import React from "react";
import WithdrawalNavs from "./WithdrawalNavs";
import WithdrawalTabs from "./WithdrawalTabs";
import DepositNavs from "./DepositNavs";
import DepositTabs from "./DepositTabs";
import { useTranslation } from "react-i18next";

const WalletTabs = () => {
	const { i18n, t } = useTranslation();
	return (
		<>
			{/* Tab panes */}
			<div className="tab-content wallet-tab-content shadow-sm card border-0">
				<div
					className="container tab-pane fade show active"
					id="pills-deposit"
					role="tabpanel"
					tabIndex="0"
				>
					<h2 className="mb-4 fw-bold text-center" style={{ color: "#0F182B" }}>
						{t("deposit")}
					</h2>
					<div>
						<DepositNavs />
						<DepositTabs />
					</div>
				</div>
				<div
					className="container tab-pane fade"
					id="pills-withdrawal"
					role="tabpanel"
					tabIndex="0"
				>
					<h2 className="mb-4 fw-bold text-center" style={{ color: "#0F182B" }}>
						{t("withdrawal")}
					</h2>
					<div>
						<WithdrawalNavs />
						<WithdrawalTabs />
					</div>
				</div>
			</div>
		</>
	);
};

export default WalletTabs;
