import React from "react";
import CardId from "../components/Profile/CardId";
import CardName from "../components/Profile/CardName";
import CardBalance from "../components/Profile/CardBalance";
import CardCredit from "../components/Profile/CardCredit";

const CardsLayout = ({ data }) => {
	return (
		<div className="container my-5">
			<div className="row">
				<div className="col-lg-3 col-md-6">
					<CardId accId={data.userId} />
				</div>
				<div className="col-lg-3 col-md-6 mt-lg-0 mt-md-0 mt-4">
					<CardName fullName={data.fullName} />
				</div>
				<div className="col-lg-3 col-md-6 mt-lg-0 mt-4">
					<CardBalance balance={data.balance} />
				</div>
				<div className="col-lg-3 col-md-6 mt-lg-0 mt-4">
					<CardCredit credit={data.credit} />
				</div>
			</div>
		</div>
	);
};

export default CardsLayout;
