import React, { useEffect, useState } from "react";
import uploadIcon from "../../assets/img/file-up-png.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { apiUrls } from "../../auth/services/baseService/UrlConfig";
import { useNavigate } from "react-router-dom";

const FileId = ({ data, onFileChange }) => {
	const { i18n, t } = useTranslation();
	const [fileName, setFileName] = useState("");
	const [isFile, setIsFile] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [file, setFile] = useState(null);
	const navigate = useNavigate()

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		onFileChange(selectedFile);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		onFileChange(selectedFile);
	};

	const handleFileRemove = () => {
		setFile(null);
		onFileChange(null);  // Notify parent component of file removal
	};

	// const handleSubmit = () => {
	// 	if (fileDetails) {
	// 		Swal.fire({
	// 			title: t("areYouSureTitle"),
	// 			text: t("areYouSureText"),
	// 			icon: "question",
	// 			showCancelButton: true,
	// 			confirmButtonColor: "#3085d6",
	// 			cancelButtonColor: "#d33",
	// 			confirmButtonText: t("confirm"),
	// 			cancelButtonText: t("cancel"),
	// 		}).then((result) => {
	// 			if (result.isConfirmed) {
	// 				const formData = new FormData();
	// 				formData.append("file", fileDetails);

	// 				axios
	// 					.post(apiUrls.identityPost(), formData, {
	// 						headers: {
	// 							"Content-Type": "multipart/form-data",
	// 							Authorization: `Bearer ${localStorage.getItem(
	// 								"jwt_access_token"
	// 							)}`,
	// 						},
	// 					})
	// 					.then((response) => {
	// 						console.log("File upload response:", response.data);
	// 						if (response.data.errors.length > 0) {
	// 							Swal.fire({
	// 								title: t("errorMessage"),
	// 								html: t("docuploaderror"),
	// 								timer: 2000,
	// 								timerProgressBar: true,
	// 							}).then(() => {
	// 								localStorage.removeItem("jwt_access_token");
	// 								localStorage.removeItem("fullname");
	// 								localStorage.removeItem("userid");
	// 								navigate("/sign-in");
	// 								window.location.reload();
	// 							});
	// 						}
	// 						else {
	// 							Swal.fire({
	// 								title: t("successMessage"),
	// 								text: t("successMessageText"),
	// 								icon: "success",
	// 							}).then(() => {
	// 								window.location.reload();
	// 							});
	// 						}
	// 					})
	// 					.catch((error) => {
	// 						console.error("Error uploading file:", error);
	// 						Swal.fire({
	// 							title: t("errorMessage"),
	// 							text: error,
	// 							icon: "warning",
	// 						});
	// 					})
	// 					.finally(() => {
	// 						setFileName("");
	// 						setFileDetails(null);
	// 					});
	// 			}
	// 		});
	// 	}
	// };

	const checkFile = () => {
		isFile.map((e) => {
			if (e.fileName === "identity") {
				setIsActive(true);
			}
		});
	};

	useEffect(() => {
		setIsFile(data);
	}, [data]);

	useEffect(() => {
		checkFile();
	}, [isFile]);

	return (
		<>
			<h5 className="upload-card-title text-center mb-3">{t("idCard")}</h5>
			{isActive ? (
				<>
					<label className="drop-container px-4">
						<i className="fa-solid fa-check icon-secondary-v2"></i>
						<div className="text-center mt-3">
							<span className="drop-title">{t("fileCardUploadedText")}</span>
						</div>
					</label>
				</>
			) : (
				<>
					<label htmlFor="filesId" className="drop-container px-4">
						<img
							src={uploadIcon}
							alt="file-upload-img"
							width="175px"
							className="mb-4"
						/>
						<div
							id="dropcontainer"
							className="text-center"
							onDragOver={handleDragOver}
							onDrop={handleDrop}
						>
							<span className="drop-title">{t("fileCardText")}</span>
							<input
								type="file"
								id="filesId"
								accept="*"
								required
								hidden
								onChange={handleFileChange}
							/>
						</div>
						{file && (
							<button
								type="button"
								className="mt-3 file-input-badge py-2 px-4 d-flex w-100 justify-content-center"
							>
								<span className="file-name">{file.name}</span>
								<span
									className="badge btn btn-outline-danger text-danger ms-3"
									onClick={handleFileRemove}
								>
									<i className="fa-regular fa-trash-can"></i>
								</span>
							</button>
						)}
					</label>
				</>
			)}
		</>
	);
};

export default FileId;
