import React from "react";
import { useTranslation } from "react-i18next";

const WalletNavs = () => {
	const { i18n, t } = useTranslation();
	return (
		<>
			{/* Nav pills */}
			<ul
				className="nav nav-pills position-absolute"
				style={{ left: "50%", transform: "translate(-50%)", top: "-65px" }}
				id="pills-tab"
				role="tablist"
			>
				<li className="nav-item wallet-nav-item">
					<button
						className="nav-link active w-100 py-4"
						data-bs-toggle="pill"
						data-bs-target="#pills-deposit"
						type="button"
						role="tab"
						aria-controls="pills-deposit"
						aria-selected="true"
					>
						{t("deposit")}
					</button>
				</li>
				<li className="nav-item wallet-nav-item">
					<button
						className="nav-link w-100 py-4"
						data-bs-toggle="pill"
						data-bs-target="#pills-withdrawal"
						type="button"
						role="tab"
						aria-controls="pills-withdrawal"
						aria-selected="false"
					>
						{t("withdrawal")}
					</button>
				</li>
			</ul>
		</>
	);
};

export default WalletNavs;
