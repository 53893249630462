import React from "react";
import { useTranslation } from "react-i18next";

const DepositNavs = () => {
	const { i18n, t } = useTranslation();
	return (
		<ul className="nav nav-tabs withdraw-navs" id="myTab" role="tablist">
			<li className="nav-item">
				<button
					className="nav-link active"
					//id="cypto-t-tab"
					data-bs-toggle="pill"
					data-bs-target="#crypto-t-tab-pane"
					type="button"
					role="tab"
					aria-controls="crypto-t-tab-pane"
					aria-selected="true"
				>
					{t("cryptocurrency")}
				</button>
			</li>
			<li className="nav-item">
				<button
					className="nav-link"
					//id="pay-methods-tab"
					data-bs-toggle="pill"
					data-bs-target="#pay-methods-tab-pane"
					type="button"
					role="tab"
					aria-controls="pay-methods-tab-pane"
					aria-selected="false"
				>
					{t("paymentMethods")}
				</button>
			</li>
		</ul>
	);
};

export default DepositNavs;
