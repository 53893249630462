import React from "react";
import { useTranslation } from "react-i18next";

const CardName = ({ fullName }) => {
	const { i18n, t } = useTranslation();
	return (
		<div className="card-wrap shadow-sm py-3">
			<div className="d-flex align-items-center justify-content-center">
				<div className="card-header d-flex align-items-center justify-content-center">
					<i className="fa-regular fa-user"></i>
				</div>
			</div>
			<div className="card-content mt-4">
				<h2 className="card-title mb-3">{t("nameSurname")}</h2>
				<p className="card-text">{fullName}</p>
			</div>
		</div>
	);
};

export default CardName;
