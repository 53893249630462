import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import img from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { apiUrls } from "../auth/services/baseService/UrlConfig";

const SignIn = () => {
	const { i18n, t } = useTranslation();
	const [form, setForm] = useState({ email: "" });

	const forgotPassHandle = (e) => {
		e.preventDefault();

		Swal.fire({
			title: t("areYouSureTitle"),
			text: t("areYouSureText"),
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("confirm"),
			cancelButtonText: t("cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.post(`${apiUrls.forgotPass()}=${form.email}`)
					.then(function (response) {
						console.log(response);
						Swal.fire({
							title: t("successMessage"),
							text: t("successMessageText"),
							icon: "success",
						});
					})
					.catch(function (error) {
						if (
							error.response.data.message ==
							'{"Message":"Email does not exist!!!"}'
						) {
							Swal.fire({
								title: t("errorMessage"),
								text: t("invalidemail"),
								icon: "warning",
							});
						} else {
							Swal.fire({
								title: t("errorMessage"),
								text: t("defaulterrormessage"),
								icon: "warning",
							});
						}
					});

				setForm({
					email: "",
				});
			}
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row vh-100">
					<div className="col-lg-6 d-flex align-items-center justify-content-end pe-lg-5">
						<div className="w-50 signin-form">
							<h3 className="signin-title">{t("forgotPassTitle")}</h3>
							<form onSubmit={forgotPassHandle}>
								<div className="mt-5">
									<label htmlFor="email" className="form-label">
										{t("email")}
									</label>
									<input
										type="text"
										name="email"
										placeholder={t("email")}
										className="form-control py-2"
										id="email"
										value={form.email}
										onChange={handleChange}
									/>
								</div>
								<button
									className="btn btn-secondary-v2 w-100 mt-4"
									type="submit"
								>
									{t("sendCode")}
								</button>
								<Link to="/sign-in">
									<button className="btn btn-secondary-v3 w-100 mt-2">
										{t("signin")}
									</button>
								</Link>
							</form>
						</div>
					</div>
					<div
						className="col-lg-6 d-flex align-items-center ps-lg-5"
						style={{ backgroundColor: "#100D28" }}
					>
						<img src={img} alt="sign-in-img" width="65%" />
					</div>
				</div>
			</div>
		</>
	);
};

export default SignIn;
