import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Welcome = ({ fullName, accId }) => {
	const { i18n, t } = useTranslation();
	const [avatarName, setAvatarName] = useState("");

	useEffect(() => {
		const splitName = fullName.split(" ");
		const name = splitName.map((e) => e[0]);
		const unifiedName = name.join("");
		setAvatarName(unifiedName);
	}, [fullName]);

	return (
		<>
			<div className="py-5 welcome-content shadow-sm border-bottom">
				<div className="container">
					<div className="d-flex flex-wrap align-items-center justify-content-between">
						<div className="d-flex align-items-start">
							<div className="avatar">
								<span>
									{avatarName}
								</span>
							</div>
							<div className="ms-4">
								<h2 className="mb-1 welcome-title">
									{t("welcome")}, {fullName}
								</h2>
								<span>
									{t("accountId")}: {accId}
								</span>
							</div>
						</div>
						<div>
							<div className="welcome-change-password">
								<Link to="/reference">
									<button className="btn btn-secondary-v2 py-2 px-4 w-100">
										{t("referencebtn")}
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Welcome;
