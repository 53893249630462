import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
	createBrowserRouter,
	RouterProvider,
	Navigate,
} from "react-router-dom";

import "./index.css";
import "./App.css";

import Profile from "./pages/Profile";
import Documents from "./pages/Documents";
import Market from "./pages/Market";
import Wallet from "./pages/Wallet";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";

import "./i18";
import NewsFeed from "./pages/NewsFeed";

import { Provider } from "react-redux";
import store from "./redux/store/store";
import ChangePassword from "./pages/ChangePassword";
import Reference from "./pages/Reference";

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import TwoFA from "./pages/TwoFA";
import TwoFactorAuthentication from "./pages/TwoFactorAuthentication";

const token = localStorage.getItem("jwt_access_token");

const router = createBrowserRouter([
	{
		path: "/",
		element: token ? <Profile /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/kyc",
		element: token ? <Documents /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/market",
		element: token ? <Market /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/wallet",
		element: token ? <Wallet /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/news-feed",
		element: token ? <NewsFeed /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/reference",
		element: token ? <Reference /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/two-factor-authentication",
		element: token ? <TwoFactorAuthentication /> : <Navigate to="/sign-in" />,
	},
	{
		path: "/sign-in",
		element: token ? <Navigate to="/" /> : <SignIn />,
	},
	{
		path: "/forgot-password",
		element: token ? <Navigate to="/" /> : <ForgotPassword />,
	},
	{
		path: "/change-password",
		element: token ? <ChangePassword /> : <Navigate to="/" />,
	},
	{
		path: "/2fa",
		element: <TwoFA />
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Suspense fallback="loading">
				<RouterProvider router={router} />
			</Suspense>
		</Provider>
	</React.StrictMode>
);
