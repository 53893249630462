// store.js

import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { loginReducer } from "../reducers/reducers"; // Örnek bir reducer

const rootReducer = combineReducers({
	auth: loginReducer,
	// Diğer reducer'lar buraya eklenebilir.
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
