import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import { apiUrls } from "../../auth/services/baseService/UrlConfig";
import { useNavigate } from "react-router-dom";

const WithdrawalTabs = () => {
	const { i18n, t } = useTranslation();
	const [cryptoForm, setCryptoForm] = useState({
		amount: null,
		coin: "BTC",
		walletAddress: "",
	});
	const [bankForm, setBankForm] = useState({
		accountOwner: "",
		identityNo: "",
		amount: null,
		currency: "TL",
		bankName: "",
		iban: "",
	});
	// const [bankTime, setBankTime] = useState(0);
	// const [cryptoTime, setCryptoTime] = useState(0);

	const navigate = useNavigate();

	// let timer = new Date();
	// let timeIntervel;
	// const handleBankTime = () => {
	// 	let clickTime = timer.getTime();
	// 	localStorage.setItem("cloudex", clickTime);
	// 	setBankTime(600000);
	// 	reduceTime();
	// };
	// const handleCryptoTime = () => {
	// 	let clickTime = timer.getTime();
	// 	localStorage.setItem("cloudexCrypto", clickTime);
	// 	setCryptoTime(600000);
	// 	reduceCryptoTime();
	// };

	// const reduceTime = async () => {
	// 	if (localStorage.getItem("cloudex")) {
	// 		timeIntervel = setInterval(() => {
	// 			setBankTime((time) => time - 500);
	// 		}, 1000);
	// 	}
	// };
	// const reduceCryptoTime = async () => {
	// 	if (localStorage.getItem("cloudexCrypto")) {
	// 		timeIntervel = setInterval(() => {
	// 			setCryptoTime((time) => time - 500);
	// 		}, 1000);
	// 	}
	// };

	// useEffect(() => {
	// 	if (localStorage.getItem("cloudex")) {
	// 		let newTime = timer.getTime();
	// 		let oldTime = localStorage.getItem("cloudex");
	// 		let timeDifference = newTime - oldTime;
	// 		if (timeDifference > 600000) {
	// 			setBankTime(0);
	// 		} else {
	// 			setBankTime(600000 - timeDifference);
	// 			reduceTime();
	// 		}
	// 	}
	// }, []);
	// useEffect(() => {
	// 	if (localStorage.getItem("cloudexCrypto")) {
	// 		let newTime = timer.getTime();
	// 		let oldTime = localStorage.getItem("cloudexCrypto");
	// 		let timeDifference = newTime - oldTime;
	// 		if (timeDifference > 600000) {
	// 			setCryptoTime(0);
	// 		} else {
	// 			setCryptoTime(600000 - timeDifference);
	// 			reduceCryptoTime();
	// 		}
	// 	}
	// }, []);

	// function millisToMinutesAndSeconds(millis) {
	// 	var minutes = Math.floor(millis / 60000);
	// 	var seconds = ((millis % 60000) / 1000).toFixed(0);
	// 	if (millis === 0 || millis < 0) {
	// 		localStorage.removeItem("cloudex");
	// 		setBankTime(0);
	// 		clearInterval(timeIntervel);
	// 	}
	// 	return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
	// }

	// function millisToMinutesAndSecondsCrypto(millis) {
	// 	var minutes = Math.floor(millis / 60000);
	// 	var seconds = ((millis % 60000) / 1000).toFixed(0);
	// 	if (millis === 0 || millis < 0) {
	// 		localStorage.removeItem("cloudexCrypto");
	// 		setCryptoTime(0);
	// 		clearInterval(timeIntervel);
	// 	}
	// 	return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
	// }

	const bankHandleChange = (e) => {
		const { name, value } = e.target;
		setBankForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	const cryptoHandleChange = (e) => {
		const { name, value } = e.target;
		setCryptoForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	const bankHandleSubmit = (e) => {
		e.preventDefault();
		Swal.fire({
			title: t("areYouSureTitle"),
			text: t("areYouSureText"),
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("confirm"),
			cancelButtonText: t("cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				let data = JSON.stringify({
					AccountOwner: bankForm.accountOwner,
					IdentityNo: bankForm.identityNo,
					Amount: bankForm.amount,
					Currency: bankForm.currency,
					BankName: bankForm.bankName,
					Iban: bankForm.iban,
				});

				let config = {
					method: "post",
					maxBodyLength: Infinity,
					url: apiUrls.postFiatWithdrawal(),
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
						"Content-Type": "application/json",
					},
					data: data,
				};

				try {
					axios
						.request(config)
						.then((response) => {
							// handleBankTime();
							console.log(response);
							Swal.fire({
								title: t("successMessage"),
								text: t("successMessageText"),
								icon: "success",
							});
						})
						.catch((error) => {
							if (error.response.data.message.includes("starting")) {
								const dynamicTime = error.response.data.message.match(/\b\d{2}:\d{2}\b/);
								Swal.fire({
									title: t("errorMessage"),
									text: t("withdrawallimit", { time: dynamicTime[0] }),
									icon: "warning",
								});
							} else if (error.response.status === 401) {
								Swal.fire({
									title: t("errorMessage"),
									html: t("fiaterror"),
									timer: 2000,
									timerProgressBar: true,
								}).then(() => {
									localStorage.removeItem("jwt_access_token");
									localStorage.removeItem("fullname");
									localStorage.removeItem("userid");
									navigate("/sign-in");
									window.location.reload();
								});
							} else {
								Swal.fire({
									title: t("errorMessage"),
									text: error,
									icon: "warning",
								});
							}
						});
				}
				catch (err) {
					console.log(err)
				}

				setBankForm({
					accountOwner: "",
					identityNo: "",
					amount: null,
					currency: "",
					bankName: "",
					iban: "",
				});
			}
		});
	};

	const cryptoHandleSubmit = (e) => {
		e.preventDefault();

		Swal.fire({
			title: t("areYouSureTitle"),
			text: t("areYouSureText"),
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("confirm"),
			cancelButtonText: t("cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				let data = JSON.stringify({
					Asset: cryptoForm.coin,
					WalletAddress: cryptoForm.walletAddress,
					Amount: cryptoForm.amount,
				});

				let config = {
					method: "post",
					maxBodyLength: Infinity,
					url: apiUrls.postCryptoWithdrawal(),
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
						"Content-Type": "application/json",
					},
					data: data,
				};

				axios
					.request(config)
					.then((response) => {
						// handleCryptoTime();
						console.log(response.data);
						Swal.fire({
							title: t("successMessage"),
							text: t("successMessageText"),
							icon: "success",
						});
					})
					.catch((error) => {
						if (error.response.data.message.includes("starting")) {
							const dynamicTime = error.response.data.message.match(/\b\d{2}:\d{2}\b/);
							Swal.fire({
								title: t("errorMessage"),
								text: t("withdrawallimit", { time: dynamicTime[0] }),
								icon: "warning",
							});
						} else if (error.response.status === 401) {
							Swal.fire({
								title: t("errorMessage"),
								html: t("cryptoerror"),
								timer: 2000,
								timerProgressBar: true,
							}).then(() => {
								localStorage.removeItem("jwt_access_token");
								localStorage.removeItem("fullname");
								localStorage.removeItem("userid");
								navigate("/sign-in");
								window.location.reload();
							});
						} else {
							Swal.fire({
								title: t("errorMessage"),
								text: error,
								icon: "warning",
							});
						}
					});

				setCryptoForm({
					amount: "",
					coin: "BTC",
					walletAddress: "",
				});
			}
		});
	};

	return (
		<div className="tab-content" id="myTabContent">
			<div
				className="tab-pane fade show active"
				id="home-tab-pane"
				role="tabpanel"
				tabIndex="0"
			>
				<form className="py-4" onSubmit={bankHandleSubmit}>
					<div className="row">
						<div className="col-lg-6">
							<label htmlFor="nameSurname" className="form-label">
								{t("accountHolderFullname")}
								<span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="nameSurname"
								name="accountOwner"
								required
								value={bankForm.accountOwner}
								onChange={bankHandleChange}
							/>
						</div>
						<div className="col-lg-6 mt-lg-0 mt-3">
							<label htmlFor="idNo" className="form-label">
								{t("accountHolderIdNo")}
								<span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="idNo"
								name="identityNo"
								required
								value={bankForm.identityNo}
								onChange={bankHandleChange}
							/>
						</div>
						<div className="col-lg-6 mt-3 position-relative">
							<label htmlFor="amount" className="form-label">
								{t("amount")} <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="amount"
								name="amount"
								required
								value={bankForm.amount}
								onChange={bankHandleChange}
							/>
							<div className="amount-badge">.00</div>
							{/* <div id="emailHelp" className="form-text text-danger">
												* Bu alanın doldurulması zorunludur.
											</div> */}
						</div>
						<div className="col-lg-6 mt-3">
							<label className="form-label">
								{t("currency")} <span className="text-danger">*</span>
							</label>
							<select
								className="form-select"
								name="currency"
								required
								value={bankForm.currency}
								onChange={bankHandleChange}
							>
								<option value="TL">TL</option>
								<option value="USD">USD</option>
								<option value="EURO">EURO</option>
							</select>
						</div>
						<div className="col-lg-6 mt-3">
							<label htmlFor="iban" className="form-label">
								IBAN <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="iban"
								name="iban"
								required
								value={bankForm.iban}
								onChange={bankHandleChange}
							/>
						</div>
						<div className="col-lg-6 mt-3">
							<label htmlFor="bankName" className="form-label">
								{t("bankName")} <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="bankName"
								name="bankName"
								required
								value={bankForm.bankName}
								onChange={bankHandleChange}
							/>
						</div>
					</div>

					<button
						type="submit"
						className="btn btn-secondary-v2 py-2 px-4 mt-4"
					>
						{t("send")}
					</button>
					{/* {bankTime > 0 ? (
						<button
							type="submit"
							className="btn btn-secondary-v2 py-2 px-4 mt-4"
							style={{ pointerEvents: "none", backgroundColor: "#87c5ff" }}
						>
							{millisToMinutesAndSeconds(bankTime)}
						</button>
					) : (null
					)} */}
				</form>
			</div>
			<div
				className="tab-pane fade"
				id="profile-tab-pane"
				role="tabpanel"
				tabIndex="0"
			>
				<form className="py-4" onSubmit={cryptoHandleSubmit}>
					<div className="row">
						<div className="col-lg-6 position-relative">
							<label htmlFor="amountCrypto" className="form-label">
								{t("amount")} <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control ps-5"
								id="amountCrypto"
								name="amount"
								required
								value={cryptoForm.amount}
								onChange={cryptoHandleChange}
							/>
							<div className="amount-crypto-badge">$</div>
						</div>
						<div className="col-lg-6 mt-lg-0 mt-3">
							<label className="form-label">
								{t("coin")} <span className="text-danger">*</span>
							</label>
							<select
								className="form-select"
								name="coin"
								required
								value={cryptoForm.coin}
								onChange={cryptoHandleChange}
							>
								<option value="BTC">BTC - BTC {t("withdrawalnetwork")}</option>
								<option value="USDT">USDT - TRC20 {t("withdrawalnetwork")}</option>
								<option value="ETH">ETH - ERC20 {t("withdrawalnetwork")}</option>
								<option value="SOL">SOL - SOLANA {t("withdrawalnetwork")}</option>
							</select>
						</div>
						<div className="col-lg-12 mt-3">
							<label htmlFor="walletAddress" className="form-label">
								{t("walletAddress")} <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="walletAddress"
								name="walletAddress"
								required
								value={cryptoForm.walletAddress}
								onChange={cryptoHandleChange}
							/>
						</div>
					</div>

					<button
						type="submit"
						className="btn btn-secondary-v2 py-2 px-4 mt-4"
					>
						{t("send")}
					</button>
					{/* {cryptoTime > 0 ? (
						<button
							type="submit"
							className="btn btn-secondary-v2 py-2 px-4 mt-4"
							style={{ pointerEvents: "none", backgroundColor: "#87c5ff" }}
						>
							{millisToMinutesAndSecondsCrypto(cryptoTime)}
						</button>
					) : ( null
					)} */}
				</form>
			</div>
		</div>
	);
};

export default WithdrawalTabs;
