const baseUrl = () => {
	if (window.location.origin.includes("localhost")) {
		return "https://cloudexcloudex.testmedici.com";
	} else if (window.location.origin.includes("test")) {
		return "https://cloudexcloudex.testmedici.com";
	} else {
		//canlı domain
		return window.location.origin.replace("pro", "cloudex");
	}
};

const proBaseUrl = () => {
	if (window.location.origin.includes("localhost")) {
		return "https://procloudex.testmedici.com";
	} else if (window.location.origin.includes("test")) {
		return "https://procloudex.testmedici.com";
	} else {
		//canlı domain
		return window.location.origin;
	}
};

export const apiUrls = {
	getMe: () => `${baseUrl()}/api/sirix/GetMe`,
	changePass: () => `${baseUrl()}/api/Sirix/ChangePassword`,
	forgotPass: () => `${proBaseUrl()}/api/core/api/Email/SendForgotPasswordEmail?email`,
	myDocuments: () => `${proBaseUrl()}/api/v1/Sca/Documents/MyDocuments`,
	identityPost: () => `${proBaseUrl()}/api/v1/Sca/Documents/Upload/identity`,
	bankPost: () => `${proBaseUrl()}/api/v1/Sca/Documents/Upload/bank`,
	locationPost: () =>
		`${proBaseUrl()}/api/v1/Sca/Documents/Upload/addressproof`,
	wt: () => `${proBaseUrl()}/api/trader/client/Trader/GetTraderUrl`,
	getCryptoData: () =>
		`${proBaseUrl()}/api/treasury/Treasury/GetCryptoPaymentMethods`,
	getBankData: () =>
		`${proBaseUrl()}/api/treasury/Treasury/GetBankPaymentMethods`,
	postFiatWithdrawal: () =>
		`${proBaseUrl()}/api/transactions/client/Transactions/CreateFiatWithdrawal`,
	postCryptoWithdrawal: () =>
		`${proBaseUrl()}/api/transactions/client/Transactions/CreateCryptoWithdrawal`,
	getReference: () =>
		`${proBaseUrl()}/api/references/client/references/GetReferenceLink`,
	get2FactorStatus: () =>
		`${proBaseUrl()}/api/authentication/Auth/Get2FactorStatus`,
	getQrCode: () =>
		`${proBaseUrl()}/api/authentication/Auth/GenerateQrCode`,
	validatePin: () =>
		`${proBaseUrl()}/api/authentication/Auth/ValidatePin`,
	disabled2FA: () =>
		`${proBaseUrl()}/api/authentication/Auth/Deactivate2Factor`,
	handleSubmit2FA: () =>
		`${proBaseUrl()}/api/authentication/Auth/VerifyTwoFactor?twoFactorPin=`,
	handleRecoverySubmit: () =>
		`${proBaseUrl()}/api/authentication/Auth/ValidateBackupCode`,
};
