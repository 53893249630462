import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { apiUrls } from "../auth/services/baseService/UrlConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Market = () => {
	const { i18n, t } = useTranslation();
	const [url, setUrl] = useState("");
	const currentLang = localStorage.getItem("lang");
	const navigate = useNavigate()

	const getMarket = async () => {
		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: apiUrls.wt(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
			},
		};

		await axios
			.request(config)
			.then((response) => {
				const updatedLang = localStorage.getItem("lang").replaceAll('"', "");
				setUrl(
					updatedLang == null
						? `${response.data.data}&lang=en`
						: `${response.data.data}&lang=${updatedLang}`
				);
			})
			.catch((error) => {
				console.log(error);
				if (error.response.status === 401) {
					localStorage.removeItem("jwt_access_token");
					localStorage.removeItem("fullname");
					localStorage.removeItem("userid");
					navigate("/sign-in");
					window.location.reload();
				}
			});
	};

	// useEffect(() => {
	// 	setCurrentLang(localStorage.getItem("lang"));
	// }, []);

	useEffect(() => {
		getMarket();
	}, [currentLang]);

	return (
		<>
			<Helmet>
				<title>Cloudex Crypto - {t("market")}</title>
			</Helmet>
			<Header />
			<div className="market-iframe">
				<iframe
					width="100%"
					height="100%"
					id="webtrader"
					title="webtrader"
					src={`${url}`}
				></iframe>
			</div>
		</>
	);
};

export default Market;
