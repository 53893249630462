import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { apiUrls } from "../../auth/services/baseService/UrlConfig";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { QRCodeCanvas } from "qrcode.react";
import Qnb from "../../assets/img/qnb.png"

const DepositTabs = () => {
	const { i18n, t } = useTranslation();
	const [cryptoData, setCryptoData] = useState([]);
	const [bankData, setBankData] = useState([]);
	const [copiedMap, setCopiedMap] = useState({});
	const [qr, setQR] = useState(false);
	const [clickId, setClickId] = useState("");

	const onCopy = (item, currency) => {
		setCopiedMap((prevState) => ({
			...prevState,
			[`${item.id}-${currency}`]: true,
		}));
		console.log(copiedMap);
		setTimeout(() => {
			setCopiedMap((prevState) => ({
				...prevState,
				[`${item.id}-${currency}`]: false,
			}));
		}, 500);
	};

	const qrcodeHandle = (e) => {
		setClickId(e);
		setQR(!qr);
	};

	const getCryptoData = () => {
		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: apiUrls.getCryptoData(),
			headers: {},
		};

		axios
			.request(config)
			.then((response) => {
				setCryptoData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getBankData = () => {
		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: apiUrls.getBankData(),
			headers: {},
		};

		axios
			.request(config)
			.then((response) => {
				setBankData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		getCryptoData();
		getBankData();
	}, []);

	const renderCryptoImage = (asset) => {
		switch (asset) {
			case "AVAX":
				return (
					<img
						src="https://cryptologos.cc/logos/avalanche-avax-logo.png"
						alt="avax"
						width="40px"
					/>
				);
			case "BNB":
				return (
					<img
						src="https://cryptologos.cc/logos/binance-coin-bnb-logo.png"
						alt="bnb"
						width="40px"
					/>
				);
			case "BTC":
				return (
					<img
						src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
						alt="btc"
						width="40px"
					/>
				);
			case "ETH":
				return (
					<img
						src="https://cryptologos.cc/logos/ethereum-eth-logo.png"
						alt="eth"
						width="40px"
					/>
				);
			case "SOL":
				return (
					<img
						src="https://cryptologos.cc/logos/solana-sol-logo.png"
						alt="sol"
						width="40px"
					/>
				);
			case "XRP":
				return (
					<img
						src="https://cryptologos.cc/logos/xrp-xrp-logo.png"
						alt="xrp"
						width="40px"
					/>
				);
			case "DOGE":
				return (
					<img
						src="https://cryptologos.cc/logos/dogecoin-doge-logo.png"
						alt="doge"
						width="40px"
					/>
				);
			case "USDT":
				return (
					<img
						src="https://cryptologos.cc/logos/tether-usdt-logo.png"
						alt="usdt"
						width="40px"
					/>
				);
			default:
				return null;
		}
	};

	const renderBankImage = (bankName) => {
		if (bankName.toLowerCase().includes("aat")) {
			// ZİRAAT BANKASI
			return (
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Ziraat_Bankas%C4%B1_logo.svg/2560px-Ziraat_Bankas%C4%B1_logo.svg.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (
			bankName.toLowerCase().includes("yapi") ||
			bankName.toLowerCase().includes("yapı")
		) {
			return (
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/d/d6/Yap%C4%B1_kredi_logo.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (
			bankName.toLowerCase().includes("vakıfbank") ||
			bankName.toLowerCase().includes("vakifbank")
		) {
			return (
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Vak%C4%B1fbank-logo.svg/2560px-Vak%C4%B1fbank-logo.svg.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (
			bankName.toLowerCase().includes("iş") ||
			bankName.toUpperCase().includes("İŞ")
		) {
			return (
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg/2560px-T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (bankName.toLowerCase().includes("kuveyt")) {
			return (
				<img
					src="https://sanalpospro.com/wp-content/uploads/2019/01/Kuveyt-turk.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (bankName.toLowerCase().includes("qnb")) {
			return (
				<img
					src={Qnb}
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (bankName.toLowerCase().includes("teb")) {
			return (
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/9/95/TEB_LOGO.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
		if (bankName.toLowerCase().includes("garanti")) {
			return (
				<img
					src="https://logos-world.net/wp-content/uploads/2021/03/Garanti-Logo.png"
					alt="bank-logo"
					width="150px"
				/>
			);
		}
	};

	return (
		<div className="tab-content" id="myTabContent">
			<div
				className="tab-pane fade show active"
				id="crypto-t-tab-pane"
				role="tabpanel"
				tabIndex="0"
			>
				<div className="row">
					{cryptoData.map((e) => (
						<div className="col-lg-4 mt-4" key={e.id}>
							<div className="card border shadow-sm deposit-crypto-card p-4 pb-5 position-relative">
								<div className="d-flex align-items-center justify-content-between">
									<h3 className="mb-0" style={{ fontWeight: "600" }}>
										{e.asset}
									</h3>
									{renderCryptoImage(e.asset)}
								</div>
								<div className="mt-4 mb-3">
									<div>
										<div className="deposit-crypto-title">Network</div>
										<div className="fw-bold mt-1">{e.network}</div>
									</div>
									<div className="mt-3">
										<div className="deposit-crypto-title">Wallet Address</div>
										<CopyToClipboard
											text={e.walletAddress}
											onCopy={() => onCopy(e, "walletAddress")}
										>
											<div className="fw-bold mt-1">
												{e.walletAddress}
												<span className="ms-2">
													<i
														className="fa-regular fa-copy"
														style={{ cursor: "pointer" }}
													></i>
												</span>
												{copiedMap[`${e.id}-walletAddress`] && (
													<div
														className="copy-alert"
														style={{
															padding: "6px",
															borderRadius: "5px 5px 0 0",
															background: "rgb(64 151 251 / 15%)",
															position: "absolute",
															width: "100%",
															left: "0",
															top: "0",
															zIndex: "12",
															textAlign: "center",
														}}
													>
														Kopyalandı!
													</div>
												)}
											</div>
										</CopyToClipboard>
									</div>
								</div>
								<div
									className="qr-box position-absolute start-0 bottom-0 w-100 text-center py-2 shadow-sm"
									onClick={() => qrcodeHandle(e.id)}
								>
									{t("qrCode")}
								</div>
								{qr && clickId === e.id && (
									<div
										style={{
											position: "absolute",
											top: "49%",
											left: "50%",
											transform: "translate(-50%, -56%)",
											backgroundColor: "#fff",
											width: "100%",
											height: "84%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											borderRadius: "5px 5px 0 0",
										}}
									>
										<QRCodeCanvas
											value={e.walletAddress}
											bgColor="transparent"
											size={192}
										/>
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
			<div
				className="tab-pane fade mb-lg-8 mb-sm-11"
				id="pay-methods-tab-pane"
				role="tabpanel"
				tabIndex="0"
			>
				<div className="row justify-content-center mt-4">
					<div className="col-lg-8">
						<div className="alert alert-warning" role="alert">
							{t("depositPaymentAlert")}
						</div>
						<p>{t("depositPaymentText")}</p>
						{bankData.map((e) => (
							<div className="card border shadow-sm deposit-crypto-card p-4 mb-5">
								<div className="row align-items-center justify-content-between">
									<div className="col-lg-8 col-md-8 col-6">
										<h3 className="mb-0" style={{ fontWeight: "600" }}>
											{e.bankName}
										</h3>
									</div>
									<div className="col-lg-4 col-md-4 col-6 text-end render-bank-images">{renderBankImage(e.bankName)}</div>
								</div>
								{/* <div className="d-flex align-items-center justify-content-between flex-wrap">
									<h3 className="mb-0" style={{ fontWeight: "600" }}>
										{e.bankName}
									</h3>
									{renderBankImage(e.bankName)}
								</div> */}
								<div className="mt-4 mb-3">
									<div>
										<div className="deposit-crypto-title">
											{t("accountHolder")}
										</div>
										<CopyToClipboard
											text={e.beneficiary}
											onCopy={() => onCopy(e, "walletAddress")}
										>
											<div className="fw-bold mt-1">
												{e.beneficiary}
												<span className="ms-2">
													<i
														className="fa-regular fa-copy"
														style={{ cursor: "pointer" }}
													></i>
												</span>
												{copiedMap[`${e.id}-walletAddress`] && (
													<div
														className="copy-alert"
														style={{
															padding: "6px",
															borderRadius: "0 0 5px 5px",
															background: "rgb(64 151 251 / 15%)",
															position: "absolute",
															width: "100%",
															left: "0",
															bottom: "0",
															textAlign: "center",
														}}
													>
														Kopyalandı!
													</div>
												)}
											</div>
										</CopyToClipboard>
									</div>
									<div className="row">
										<div className="col-lg-3 col-4">
											<div className="mt-3">
												<div className="deposit-crypto-title">
													{t("currency")}
												</div>
											</div>
										</div>
										<div className="col-lg-9 col-8">
											<div className="mt-3">
												<div className="deposit-crypto-title">IBAN</div>
											</div>
										</div>
									</div>
									{Object.entries(e.ibans).map(([currency, iban]) => (
										<div className="row">
											<div className="col-lg-3 col-4">
												<div>
													<div className="mt-3">
														<div className="fw-bold mt-1">{currency}</div>
													</div>
												</div>
											</div>
											<div className="col-lg-9 col-8">
												<div className="mt-3 position-relative">
													<CopyToClipboard
														text={iban}
														onCopy={() => onCopy(e, "walletAddress")}
													>
														<div className="fw-bold mt-1">
															{iban}
															<span className="ms-2">
																<i
																	className="fa-regular fa-copy"
																	style={{ cursor: "pointer" }}
																></i>
															</span>
															{copiedMap[`${iban}-walletAddress`] && (
																<div
																	className="copy-alert"
																	style={{
																		padding: "6px",
																		borderRadius: "0 0 5px 5px",
																		background: "rgb(64 151 251 / 15%)",
																		textAlign: "center",
																	}}
																>
																	Kopyalandı!
																</div>
															)}
														</div>
													</CopyToClipboard>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
					<div className="d-flex align-items-center justify-content-between flex-wrap bank-logos position-absolute bottom-0 start-0 end-0">
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Ziraat_Bankas%C4%B1_logo.svg/2560px-Ziraat_Bankas%C4%B1_logo.svg.png"
							alt="bank-logo"
							width="105px"
						/>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/d/d6/Yap%C4%B1_kredi_logo.png"
							alt="bank-logo"
							width="100px"
						/>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Vak%C4%B1fbank-logo.svg/2560px-Vak%C4%B1fbank-logo.svg.png"
							alt="bank-logo"
							width="90px"
						/>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg/2560px-T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg.png"
							alt="bank-logo"
							width="100px"
							className="mt-3 mt-md-0 mt-lg-0"
						/>
						<img
							src="https://sanalpospro.com/wp-content/uploads/2019/01/Kuveyt-turk.png"
							alt="bank-logo"
							width="110px"
							className="mt-3 mt-md-0 mt-lg-0"
						/>
						<img
							src={Qnb}
							alt="bank-logo"
							width="95px"
							className="mt-3 mt-md-0 mt-lg-0"
						/>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/9/95/TEB_LOGO.png"
							alt="bank-logo"
							width="95px"
							className="mt-3 mt-md-0 mt-lg-0"
						/>
						<img
							src="https://logos-world.net/wp-content/uploads/2021/03/Garanti-Logo.png"
							alt="bank-logo"
							width="95px"
							className="mt-3 mt-md-0 mt-lg-0"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepositTabs;
