import React from "react";
import Header from "../components/Header/Header";
import WalletLayout from "../layouts/WalletLayout";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Wallet = () => {
	const { i18n, t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>Cloudex Crypto - {t("wallet")}</title>
			</Helmet>
			<Header />
			<WalletLayout />
		</>
	);
};

export default Wallet;
