import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import img from "../assets/img/logo.png";
import Swal from "sweetalert2";
import Header from "../components/Header/Header";
import axios from "axios";
import { apiUrls } from "../auth/services/baseService/UrlConfig";

const ChangePassword = () => {
	const { i18n, t } = useTranslation();
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [newRepass, setNewRepass] = useState("");
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [showOldPass, setShowOldPass] = useState(false);
	const [showNewPass, setShowNewPass] = useState(false);
	const [showRepass, setShowRepass] = useState(false);

	const changePassHandle = (e) => {
		e.preventDefault();

		Swal.fire({
			title: t("areYouSureTitle"),
			text: t("areYouSureText"),
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("confirm"),
			cancelButtonText: t("cancel"),
		}).then((result) => {
			if (result.isConfirmed) {
				const formData = new FormData();
				formData.append("newPass", newPass);
				formData.append("oldPass", oldPass);
				axios
					.post(
						`${apiUrls.changePass()}?oldPassword=${oldPass}&newPassword=${newPass}`,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
								Authorization: `${localStorage.getItem("jwt_access_token")}`,
							},
						}
					)
					.then((response) => {
						Swal.fire({
							title: t("successMessage"),
							text: t("changePassSuccessText"),
							icon: "success",
						});
					})
					.catch((error) => {
						console.log(error.response.data);
						if (error.response.status === 400) {
							Swal.fire({
								title: t("errorMessage"),
								text: t("changePassErrorText"),
								icon: "error",
							});
						} else {
							console.error("An error occurred:", error);
						}
					});
			}
		});
	};

	const checkPass = () => {
		return newRepass === newPass;
	};

	useEffect(() => {
		setButtonDisabled(!checkPass());
		if (newPass == "" || newRepass == "") {
			setButtonDisabled(true);
		}
	}, [newPass, newRepass]);

	useEffect(() => {
		setButtonDisabled(true);
	}, []);

	return (
		<>
			<Header />
			<div className="container-fluid">
				<div className="row" style={{ height: "calc(100vh - 117px)" }}>
					<div className="col-lg-6 d-flex align-items-center justify-content-end pe-lg-5">
						<div className="w-50 signin-form">
							<h3 className="signin-title">{t("changePassword")}</h3>
							<form onSubmit={changePassHandle}>
								<div className="mb-3 mt-5 position-relative">
									<label htmlFor="oldpass" className="form-label">
										{t("oldpassword")}
									</label>
									<input
										type={`${showOldPass ? "text" : "password"}`}
										name="oldpass"
										placeholder={t("oldpassword")}
										className="form-control py-2"
										id="oldpass"
										value={oldPass}
										onChange={(e) => setOldPass(e.target.value)}
										required
									/>
									<i
										className={`fa-regular ${
											showOldPass ? "fa-eye-slash" : "fa-eye"
										}`}
										style={{
											position: "absolute",
											bottom: "12px",
											right: "12px",
											cursor: "pointer",
										}}
										onClick={() => setShowOldPass(!showOldPass)}
									></i>
								</div>
								<div className="mb-3 position-relative">
									<label htmlFor="password" className="form-label">
										{t("newpassword")}
									</label>
									<div>
										<input
											type={`${showNewPass ? "text" : "password"}`}
											name="password"
											placeholder={t("newpassword")}
											value={newPass}
											className="form-control rounded py-2"
											id="password"
											onChange={(e) => setNewPass(e.target.value)}
											required
										/>
										<i
											className={`fa-regular ${
												showNewPass ? "fa-eye-slash" : "fa-eye"
											}`}
											style={{
												position: "absolute",
												bottom: "12px",
												right: "12px",
												cursor: "pointer",
											}}
											onClick={() => setShowNewPass(!showNewPass)}
										></i>
									</div>
								</div>
								<div className="position-relative">
									<label htmlFor="repassword" className="form-label">
										{t("newrepassword")}
									</label>
									<div>
										<input
											type={`${showRepass ? "text" : "password"}`}
											name="repassword"
											placeholder={t("newrepassword")}
											value={newRepass}
											className="form-control rounded py-2"
											id="password"
											onChange={(e) => setNewRepass(e.target.value)}
											required
										/>
										<i
											className={`fa-regular ${
												showRepass ? "fa-eye-slash" : "fa-eye"
											}`}
											style={{
												position: "absolute",
												bottom: "12px",
												right: "12px",
												cursor: "pointer",
											}}
											onClick={() => setShowRepass(!showRepass)}
										></i>
									</div>
								</div>
								<button
									className="btn btn-secondary-v2 w-100 mt-4"
									type="submit"
									disabled={buttonDisabled}
								>
									{t("changePassword")}
								</button>
							</form>
						</div>
					</div>
					<div
						className="col-lg-6 d-flex align-items-center ps-lg-5 justify-content-center"
						style={{ backgroundColor: "#100D28" }}
					>
						<img src={img} alt="sign-in-img" width="65%" />
					</div>
				</div>
			</div>
		</>
	);
};

export default ChangePassword;
