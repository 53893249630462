import React, { useEffect, useState } from 'react'
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Ios from "../assets/img/ios.png";
import Android from "../assets/img/android.png";
import axios from 'axios';
import Swal from "sweetalert2";
import jsQR from 'jsqr';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { apiUrls } from '../auth/services/baseService/UrlConfig';

const TwoFactorAuthentication = () => {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [qrImg, setQrImg] = useState("");
    const [pin, setPin] = useState("");
    const [backupCode, setBackupCode] = useState([]);
    const [copiedMap, setCopiedMap] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [copyAccName, setCopyAccName] = useState(false);
    const [copySetupKey, setCopySetupKey] = useState(false);

    const [secretKey, setSecretKey] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const onCopy = (item) => {
        setCopiedMap((prevState) => ({
            ...prevState,
            [`${item}`]: true,
        }));
        setTimeout(() => {
            setCopiedMap((prevState) => ({
                ...prevState,
                [`${item}`]: false,
            }));
        }, 500);
    };

    const get2FactorStatus = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiUrls.get2FactorStatus(),
            headers: {
                'authorization': `Bearer ${localStorage.getItem("jwt_access_token")}`
            }
        };

        try {
            setIsSubmitting(true)
            const response = await axios.request(config);
            setData(response.data.data);
            setIsSubmitting(false)
            return response.data.data; // return data so we can use it for conditional chaining
        } catch (error) {
            console.log(error);
            return null; // return null in case of error to handle logic later
        }
    }

    const getSecretKey = async (qrImgUrl) => {
        try {
            // BASE64
            const img = new Image();
            img.src = qrImgUrl;  // qrImg yerine parametre kullanıyoruz
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // jsQR ile QR kodu çözme
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const qrCode = jsQR(imageData.data, canvas.width, canvas.height);

                if (qrCode) {
                    const otpauthUri = qrCode.data;
                    const url = new URL(otpauthUri);
                    const secret = url.searchParams.get('secret');
                    const email = qrCode.data.split(':')[2].split('?')[0];
                    const decodedEmail = decodeURIComponent(email);

                    setSecretKey(secret);
                    setUserEmail(decodedEmail);
                } else {
                    console.log("QR kodu çözülemedi.");
                }
            };
        } catch (error) {
            console.log("error", error);
        }
    };

    const getQrCode = async () => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrls.getQrCode(),
            headers: {
                'authorization': `Bearer ${localStorage.getItem("jwt_access_token")}`,
            }
        };

        try {
            const response = await axios.request(config);
            const qrCodeUrl = response.data.qrCodeUrl;
            setQrImg(qrCodeUrl);  // QR kodu URL'sini set ediyoruz
            await getSecretKey(qrCodeUrl);  // QR kodu tamamen yüklendikten sonra secretKey'i çözüyoruz
        } catch (error) {
            console.log(error);
        }
    };

    const validatePin = (e) => {
        e.preventDefault();

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrls.validatePin(),
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem("jwt_access_token")}`,
            },
            data: JSON.stringify(pin)
        };

        axios.request(config)
            .then((response) => {
                setBackupCode(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const disabled2FA = () => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrls.disabled2FA(),
            headers: {
                'authorization': `Bearer ${localStorage.getItem("jwt_access_token")}`
            }
        };

        Swal.fire({
            title: t("areYouSureTitle"),
            text: t("disabled2facontrol"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("confirm"),
            cancelButtonText: t("cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                axios.request(config)
                    .then((response) => {
                        Swal.fire({
                            title: t("successMessage"),
                            html: t("success2facontrol"),
                            timer: 4000000,
                            timerProgressBar: true,
                            confirmButtonColor: "#0485ff",
                            confirmButtonText: t("done")
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            const status = await get2FactorStatus();
            if (!status && !data) {
                await getQrCode();  // QR kodu ve secretKey'i çözme sırasını burada yönetiyoruz
            }
        };

        fetchData();
    }, [data]);

    return (
        <>
            <Helmet>
                <title>Cloudex Crypto - {t("2fa")}</title>
            </Helmet>
            <Header />
            <div className="container my-5">
                <div className="two-factor-card shadow-sm rounded">
                    {
                        !data ?
                            // DISABLED
                            <div className={`row justify-content-${backupCode.length > 0 ? "center" : "between"}`}>
                                {
                                    backupCode.length > 0 ?
                                        <div className='col-lg-8'>
                                            <div className='mb-5 text-center'>
                                                <img width="72" height="72" src="https://img.icons8.com/color/72/checked--v1.png" alt="checked--v1" />
                                                <h3 className='text-success'>{t("success2fa")}</h3>
                                            </div>
                                            <h5 className='mb-3'>{t("saverecoverycode")}</h5>
                                            <p className="mb-0">{t("success2fatext")}</p>
                                            <div className='mt-4'>
                                                <h5>{t("recoverycodes")}</h5>
                                                <p>{t("recoverycodestext")}</p>
                                                {
                                                    backupCode.map((e, index) => (
                                                        <div className='mb-3'>
                                                            <p className='mb-1' style={{ fontWeight: 600 }}>{index + 1}. {t("recoverycode")}</p>
                                                            <p className='fw-normal mb-0' style={{ wordBreak: "break-all" }}>{e.code}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='mt-4'>
                                                <div className="alert alert-warning" role="alert">
                                                    <p>{t("recoveryalert")}</p>

                                                    <p className='mb-0'>{t("recoveryalert2")}</p>
                                                </div>
                                            </div>
                                            <button className='btn btn-secondary-v2 mt-3 px-5 py-2 w-100' onClick={() => window.location.reload()}>{t("done")}</button>
                                        </div>
                                        :
                                        <>
                                            <div className="col-lg-7">
                                                <h3 className='mb-3'>{t("2fapagetitle")}</h3>
                                                <p className="mb-0">
                                                    {t("2fapagetext1")}
                                                </p>
                                                <p className="mb-0">
                                                    {t("2fapagetext2")}
                                                </p>

                                                {/* START */}
                                                <div className='mt-5'>
                                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                        <li className="nav-item me-2" role="presentation">
                                                            <button className="nav-link rounded active py-3 px-5" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t("qrcodesetup")}</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link rounded py-3 px-5" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{t("manuelsetup")}</button>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                                            <div className='mt-5'>
                                                                <div className="mb-5 step-one">
                                                                    <h5 className='mb-2'>{t("2fastep1qr")}</h5>
                                                                    <p className='mb-0'>{t("2fastep1textqr")}</p>
                                                                    <div className='mt-3'>
                                                                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target='_blank'>
                                                                            <img src={Ios} alt="ios" width="150px" className='me-2' />
                                                                        </a>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target='_blank'>
                                                                            <img src={Android} alt="android" width="150px" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-5 step-two">
                                                                    <h5 className='mb-2'>{t("2fastep2qr")}</h5>
                                                                    <p className='mb-0'>{t("2fastep2textqr")}</p>
                                                                </div>
                                                                <div className="mb-5 step-three">
                                                                    <h5 className='mb-2'>{t("2fastep3qr")}</h5>
                                                                    <p className='mb-0'>{t("2fastep3textqr")}</p>
                                                                </div>
                                                                <div className="mb-0 step-four">
                                                                    <h5 className='mb-2'>{t("2fastep4qr")}</h5>
                                                                    <p className='mb-0'>{t("2fastep4textqr")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                                            <div className='mt-5'>
                                                                <div className="mb-5 step-one">
                                                                    <h5 className='mb-2'>{t("2fastep1manuel")}</h5>
                                                                    <p className='mb-0'>{t("2fastep1textmanuel")}</p>
                                                                    <div className='mt-3'>
                                                                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target='_blank'>
                                                                            <img src={Ios} alt="ios" width="150px" className='me-2' />
                                                                        </a>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target='_blank'>
                                                                            <img src={Android} alt="android" width="150px" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-5 step-two">
                                                                    <h5 className='mb-2'>{t("2fastep2manuel")}</h5>
                                                                    <p className='mb-0'>{t("2fastep2textmanuel")}</p>
                                                                </div>
                                                                <div className="mb-5 step-three">
                                                                    <h5 className='mb-2'>{t("2fastep3manuel")}</h5>
                                                                    <p className='mb-0'>{t("2fastep3textmanuel")}</p>
                                                                </div>
                                                                <div className="mb-0 step-four">
                                                                    <h5 className='mb-2'>{t("2fastep4manuel")}</h5>
                                                                    <p className='mb-0'>{t("2fastep4textmanuel")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* END */}
                                            </div>
                                            <div className="col-lg-3 mt-lg-0 mt-md-0 mt-4">
                                                <img src={qrImg} alt="qr-code" width="100%" />
                                                <form className='mt-4 pb-4' onSubmit={validatePin}>
                                                    <label htmlFor="pin">{t("2facode")}</label>
                                                    <input type="text" id='pin' name='pin' className='form-control mt-1' maxLength={6} onChange={(e) => setPin(e.target.value)} />
                                                    <button type='submit' className='btn btn-secondary-v2 w-100 mt-3'>{t("send")}</button>
                                                </form>
                                                <div className='border-top pt-4'>
                                                    <div className='mb-3'>
                                                        <p className='mb-0' style={{ fontSize: 18 }}>{t("accountname")}</p>
                                                        <CopyToClipboard
                                                            text={userEmail}
                                                            onCopy={() => onCopy(userEmail)}
                                                        >
                                                            <p style={{ fontWeight: 400 }} onClick={() => {
                                                                setCopyAccName(true)
                                                                setTimeout(() => {
                                                                    setCopyAccName(false)
                                                                }, 1000);
                                                            }}>{userEmail} <i style={{ cursor: "pointer" }} className={`ms-2 ${copyAccName ? "fa-solid fa-check text-success" : "fa-regular fa-copy"}`}></i></p>
                                                        </CopyToClipboard>
                                                    </div>
                                                    <div>
                                                        <p className='mb-0' style={{ fontSize: 18 }}>{t("secretkey")}</p>
                                                        <CopyToClipboard
                                                            text={secretKey}
                                                            onCopy={() => onCopy(secretKey)}
                                                        >
                                                            <p style={{ fontWeight: 400 }} onClick={() => {
                                                                setCopySetupKey(true)
                                                                setTimeout(() => {
                                                                    setCopySetupKey(false)
                                                                }, 1000);
                                                            }}>{secretKey} <i style={{ cursor: "pointer" }} className={`ms-2 ${copySetupKey ? "fa-solid fa-check text-success" : "fa-regular fa-copy"}`}></i></p>
                                                        </CopyToClipboard>
                                                    </div>
                                                    <div>
                                                        <p className='mb-0' style={{ fontSize: 18 }}>{t("secrettype")}</p>
                                                        <p style={{ fontWeight: 400 }}>{t("timebased")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                            :
                            // ENABLED
                            <div className='row'>
                                <div className="col-lg-7 mx-auto">
                                    <div>
                                        <h4 className="mb-3">{t("disabled2fa")}</h4>
                                        <p>
                                            {t("disabled2fatext1")}
                                        </p>
                                        <p>
                                            {t("disabled2fatext2")}
                                        </p>
                                    </div>
                                    <div className="mt-3 d-flex flex-column">
                                        <label className="me-2">
                                            {t("clickdisabled2fa")}
                                        </label>
                                        <div className='mt-3'>
                                            <button className='btn btn-secondary-v2 py-2 px-4' onClick={disabled2FA}>{t("disabled")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default TwoFactorAuthentication