const jwtServiceConfig = {
	signIn: "api/auth/sign-in",
	signUp: "api/auth/sign-up",
	accessToken: "api/auth/access-token",
	updateUser: "api/auth/user/update",
	apiSirix: "/api/sirix",
	apiV1: "/api/v1/Sca",
	crmUrl: "/api/v1/Sca/Identity/Crm",
	crmApiUrl: "/api/v1/crm/Crm",
	withdrawalSubmit: "/api/v1/Sca/Treasury/MoneyTransfer/withdrawal/Submit",
	login: "/api/authentication/Auth/Login"
};

export default jwtServiceConfig;
