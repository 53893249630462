import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LANGUAGES } from "../../constants";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import LogoPng from "../../assets/img/logo-png.png";
import Ios from "../../assets/img/ios.png";
import Android from "../../assets/img/android.png";
import Apk from "../../assets/img/apk.png";
import ApkFile from "../../assets/cloudex.apk"

const Header = () => {
	const [selectedLanguage, setSelectedLanguage] = useState(
		JSON.parse(localStorage.getItem("lang"))
			? JSON.parse(localStorage.getItem("lang"))
			: "en"
	);
	const [currentLink, setCurrentLink] = useState(window.location.pathname);
	const [fullName, setFullname] = useState(
		localStorage.getItem("fullname") ? localStorage.getItem("fullname") : ""
	);
	const [userId, setUserId] = useState(
		localStorage.getItem("userid") ? localStorage.getItem("userid") : ""
	);

	const { i18n, t } = useTranslation();
	const onChangeLang = (e) => {
		const lang_code = e.code;
		i18n.changeLanguage(lang_code);
		setSelectedLanguage(e.code);
	};

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(selectedLanguage));
	}, []);

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(selectedLanguage));
	}, [selectedLanguage]);

	useEffect(() => {
		const interval = setInterval(() => {
			const storedFullName = localStorage.getItem("fullname");
			const storedUserid = localStorage.getItem("userid");
			if (storedFullName) {
				setFullname(storedFullName);
				setUserId(storedUserid);
				clearInterval(interval);
			}
		}, 100);
		return () => clearInterval(interval);
	});

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "none",
			border: "none",
			color: "#fff",
		}),
		menu: (provided, state) => ({
			...provided,
			background: "none",
			color: "#fff",
			width: "150px",
		}),
		option: (provided, state) => ({
			...provided,
			background: state.isFocused ? "#f0f0f0" : "white",
			color: "#000",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "rgb(148 163 184/1)",
		}),
		indicatorSeparator: (provided, state) => ({
			display: "none",
		}),
		dropdownIndicator: (provided, state) => ({
			display: "none",
		}),
	};

	const customStylesMobile = {
		control: (provided, state) => ({
			...provided,
			background: "none",
			border: "none",
			color: "#fff",
			marginBottom: "20px",
		}),
		menu: (provided, state) => ({
			...provided,
			background: "none",
			color: "#fff",
			width: "fit-content",
		}),
		option: (provided, state) => ({
			...provided,
			background: state.isFocused ? "#f0f0f0" : "white",
			color: "#000",
			padding: "15px",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#fff",
			fontSize: "20px",
		}),
		indicatorSeparator: (provided, state) => ({
			display: "none",
		}),
		dropdownIndicator: (provided, state) => ({
			display: "none",
		}),
	};

	const navigate = useNavigate();

	const signOutHandle = () => {
		localStorage.removeItem("jwt_access_token");
		localStorage.removeItem("fullname");
		localStorage.removeItem("userid");
		navigate("/sign-in");
		window.location.reload();
	};

	return (
		<>
			<div className="bg-primary-v2">
				<div className="container py-3">
					<div className="d-flex align-items-center justify-content-between">
						<div>
							<Link to="/">
								<img src={LogoPng} alt="logo" width="100px" />
							</Link>
						</div>
						<div className="navbar-links">
							<Link to="/" className={currentLink === "/" ? "link-active" : ""}>
								<i className="fa-regular fa-user me-2"></i> {t("profile")}
							</Link>
							<Link
								to="/kyc"
								className={currentLink === "/kyc" ? "link-active" : ""}
							>
								<i className="fa-regular fa-file-lines me-2"></i>{" "}
								{t("KYC")}
							</Link>
							<Link
								to="/market"
								className={currentLink === "/market" ? "link-active" : ""}
							>
								<i className="fa-solid fa-chart-line me-2"></i> {t("webTrader")}
							</Link>
							<Link
								to="/wallet"
								className={currentLink === "/wallet" ? "link-active" : ""}
							>
								<i className="fa-solid fa-wallet me-2"></i> {t("wallet")}
							</Link>
							<Link
								to="/news-feed"
								className={currentLink === "/news-feed" ? "link-active" : ""}
							>
								<i className="fa-regular fa-newspaper me-2"></i> {t("newsFeed")}
							</Link>
						</div>
						<div className="d-flex flex-column align-items-center market-links">
							<div className="">
								<Link
									to="https://apps.apple.com//app/cc-pro/id6737321757"
									target="_blank"
								>
									<img src={Ios} alt="" width="120px" className="me-2" />
								</Link>
								<a
									href={ApkFile}
									download="cloudex.apk"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={Apk} alt="Download APK" width="120px" />
								</a>

							</div>

						</div>
						<div className="d-flex align-items-center navbar-end">
							{/* <div className="me-4">
								<i className="fa-brands fa-whatsapp me-2 fa-xl wp-icon"></i>
							</div> */}
							<Select
								className="language-select-box"
								defaultValue={
									LANGUAGES.find((lang) => lang.code === selectedLanguage)
										? LANGUAGES.find((lang) => lang.code === selectedLanguage)
										: LANGUAGES.find((lang) => lang.code === "en")
								}
								// value={LANGUAGES.find((lang) => lang.code === selectedLanguage)}
								onChange={onChangeLang}
								styles={customStyles}
								isSearchable={false}
								options={LANGUAGES}
								getOptionLabel={(option) => (
									<div className="d-flex align-items-center">
										<img
											src={option.flag}
											alt={option.label}
											width="30px"
											className="me-2"
										/>
										{option.label}
									</div>
								)}
							/>
							<div className="dropdown ms-4">
								<div
									className="dropdown-toggle "
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<div className="d-flex align-items-center">
										{fullName ? (
											<div>{fullName}</div>
										) : (
											<div className="username-container"></div>
										)}
										<div className="avatar-small ms-2">
											<span>
												<i className="fa-regular fa-user"></i>
											</span>
										</div>
									</div>
								</div>
								<div className="dropdown-menu">
									<div>
										<Link to="/two-factor-authentication" className="text-decoration-none">
											<button className="btn w-100 text-start px-3 d-flex align-items-center">
												<div className="icon-container">
													<img width="24" height="24" src="https://img.icons8.com/dotty/48/sms-token.png" alt="sms-token" className="me-3" />
												</div>
												{t("2fa")}
											</button>
										</Link>
										<Link to="/change-password" className="text-decoration-none">
											<button className="btn w-100 text-start px-3 d-flex align-items-center">
												<div className="icon-container">
													<img width="20" height="20" src="https://img.icons8.com/ios/50/password--v1.png" alt="password--v1" className="me-3" />
												</div>
												{t("changePassword")}
											</button>
										</Link>
										<button
											className="btn w-100 text-start px-3 d-flex align-items-center"
											onClick={signOutHandle}
										>
											<div className="icon-container">
												<img width="19" height="19" src="https://img.icons8.com/ios/46/exit--v1.png" alt="exit--v1" className="me-3" />
											</div>
											{t("signOut")}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="mobile-header">
							<button
								className="btn btn-primary-v2"
								type="button"
								data-bs-toggle="offcanvas"
								data-bs-target="#offcanvasExample"
							>
								<i className="fa-solid fa-bars fa-xl"></i>
							</button>
							<div
								className="offcanvas offcanvas-start"
								tabIndex={-1}
								id="offcanvasExample"
								data-bs-backdrop="static"
							>
								<div className="offcanvas-header mb-3">
									<h5 className="offcanvas-title" id="offcanvasExampleLabel">
										<Link to="/">
											<img src={LogoPng} alt="logo" width="90px" />
										</Link>
									</h5>
									<button
										type="button"
										className="btn-close-custom"
										data-bs-dismiss="offcanvas"
										aria-label="Close"
									>
										<img
											src="https://img.icons8.com/fluency-systems-regular/100/ffffff/delete-sign--v1.png"
											alt="close"
											className="btn-close-custom"
											width="30px"
										/>
									</button>
								</div>
								<div style={{ overflow: "auto" }}>
									<div
										className="py-4 px-3 shadow-sm border"
										style={{ backgroundColor: "#dfe7f1" }}
									>
										<div className="d-flex align-items-center justify-content-between">
											<div className="d-flex align-items-center">
												<div className="avatar">
													<span className="text-primary-v2">
														<i className="fa-regular fa-user"></i>
													</span>
												</div>
												<div className="ms-3">
													<h2 className="mb-1 welcome-title">{fullName}</h2>
													<span>
														{t("accountId")}: {userId}
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="mobile-navbar-links">
										<div className="d-flex flex-column px-3 my-4">
											<div className="mobile-change-lang">
												<Select
													className="language-select-box"
													value={LANGUAGES.find(
														(lang) => lang.code === selectedLanguage
													)}
													onChange={onChangeLang}
													styles={customStylesMobile}
													options={LANGUAGES}
													isSearchable={false}
													getOptionLabel={(option) => (
														<div className="d-flex align-items-center">
															<img
																src={option.flag}
																alt={option.label}
																width="40px"
																className="me-3"
															/>
															{option.label}
														</div>
													)}
												/>
											</div>
											<Link
												to="/"
												className={currentLink === "/" ? "link-active" : ""}
											>
												<i className="fa-regular fa-user me-2"></i>{" "}
												{t("profile")}
											</Link>
											<Link
												to="/kyc"
												className={
													currentLink === "/kyc" ? "link-active" : ""
												}
											>
												<i className="fa-regular fa-file-lines me-2"></i>{" "}
												{t("KYC")}
											</Link>
											<Link
												to="/market"
												className={
													currentLink === "/market" ? "link-active" : ""
												}
											>
												<i className="fa-solid fa-chart-line me-2"></i>{" "}
												{t("webTrader")}
											</Link>
											<Link
												to="/wallet"
												className={
													currentLink === "/wallet" ? "link-active" : ""
												}
											>
												<i className="fa-solid fa-wallet me-2"></i>{" "}
												{t("wallet")}
											</Link>
											<Link
												to="/news-feed"
												className={
													currentLink === "/news-feed" ? "link-active" : ""
												}
											>
												<i className="fa-regular fa-newspaper me-2"></i>{" "}
												{t("newsFeed")}
											</Link>
											<Link
												to="/two-factor-authentication"
												className={
													currentLink === "/two-factor-authentication"
														? "link-active"
														: ""
												}
											>
												<i className="fa-solid fa-lock me-2"></i>
												{t("2fa")}
											</Link>
											<Link
												to="/change-password"
												className={
													currentLink === "/change-password"
														? "link-active"
														: ""
												}
											>
												<i className="fa-solid fa-key me-2"></i>
												{t("changePassword")}
											</Link>
											<Link
												to="/reference"
												className={
													currentLink === "/reference"
														? "link-active"
														: ""
												}
											>
												<i className="fa-solid fa-users me-2"></i>
												{t("reference")}
											</Link>
											<button
												className="btn w-100 text-start"
												onClick={signOutHandle}
												style={{
													fontSize: "20px",
													color: "#d9d9d9",
													fontWeight: "600",
													padding: "15px 20px",
												}}
											>
												<i className="fa-solid fa-right-from-bracket me-2"></i>{" "}
												{t("signOut")}
											</button>
											<div className="d-flex align-items-center mt-4 flex-wrap">
												<Link
													to="https://apps.apple.com//app/cc-pro/id6737321757"
													target="_blank"
												>
													<img src={Ios} alt="" width="140px" />
												</Link>
												<a
													href={ApkFile}
													download="cloudex.apk"
													target="_blank"
													rel="noopener noreferrer"
												>
													<img src={Apk} alt="Download APK" width="140px" />
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="header-bottom pb-3 pt-2 text-end market-links-2">
				<div className="container">
					<div className="d-flex align-items-center justify-content-end">
						<div className="">

							<Link
								to="https://apps.apple.com//app/cc-pro/id6737321757"
								target="_blank"
							>
								<img src={Ios} alt="" width="120px" className="me-2" />
							</Link>
							<a
								href={ApkFile}
								download="cloudex.apk"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={Apk} alt="Download APK" width="120px" />
							</a>
						</div>

					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
