import React from "react";
import WalletNavs from "../components/Wallet/WalletNavs";
import WalletTabs from "../components/Wallet/WalletTabs";

const WalletLayout = () => {
	return (
		<>
			<div className="container my-4 position-relative">
				<WalletNavs />
				<WalletTabs />
			</div>
		</>
	);
};

export default WalletLayout;
