import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Welcome from "../components/Header/Welcome";
import ProfileCards from "../components/Profile/ProfileCards";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { apiUrls } from "../auth/services/baseService/UrlConfig";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";

const Profile = () => {
	const { i18n, t } = useTranslation();
	let [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		balance: 0,
		credit: 0,
		fullName: "",
		group: "",
		userId: "",
	});
	const navigate = useNavigate();

	const getMe = () => {
		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: apiUrls.getMe(),
			headers: {
				Authorization: localStorage.getItem("jwt_access_token"),
			},
		};

		axios
			.request(config)
			.then((response) => {
				const { Balance, Credit, FullName, Group, UserId } = response.data;
				setData({
					balance: Balance,
					credit: Credit,
					fullName: FullName,
					group: Group,
					userId: UserId,
				});
				localStorage.setItem("fullname", FullName);
				localStorage.setItem("userid", UserId);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error.response.status);
				if (error.response.status !== 200) {
					localStorage.removeItem("jwt_access_token");
					window.location.reload();
				}
			});
	};

	useEffect(() => {
		if (localStorage.getItem("jwt_access_token")) {
			getMe();
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>Cloudex Crypto</title>
			</Helmet>
			<>
				<Header />
				{loading ? (
					<div
						className="d-flex align-items-center justify-content-center"
						style={{ height: "calc(100vh - 117px)" }}
					>
						<BounceLoader
							color="#36d7b7"
							loading={loading}
							size={150}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				) : (
					<>
						<Welcome fullName={data.fullName} accId={data.userId} />
						<ProfileCards data={data} />
					</>
				)}
			</>
		</>
	);
};

export default Profile;
