import React from "react";
import Header from "../components/Header/Header";
import FilesLayout from "../layouts/FilesLayout";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Documents = () => {
	const { i18n, t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>Cloudex Crypto - {t("KYC")}</title>
			</Helmet>
			<Header />
			<FilesLayout />
		</>
	);
};

export default Documents;
