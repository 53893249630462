import React, { useEffect, useState } from 'react';
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import BounceLoader from "react-spinners/BounceLoader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { apiUrls } from '../auth/services/baseService/UrlConfig';

const Reference = () => {
    const { i18n, t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [reference, setReference] = useState({ data: {} });
    const [copiedMap, setCopiedMap] = useState({});

    const onCopy = (item, val) => {
        setCopiedMap((prevState) => ({
            ...prevState,
            [`${item}-${val}`]: true,
        }));
        setTimeout(() => {
            setCopiedMap((prevState) => ({
                ...prevState,
                [`${item}-${val}`]: false,
            }));
        }, 500);
    };

    const getReferenceLink = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiUrls.getReference(),
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("jwt_access_token")}`
            }
        };

        axios.request(config)
            .then((response) => {
                const referenceLink = response.data.data.refferenceLink;
                const modifiedReferenceLink = modifyReferenceLinkForEnvironment(referenceLink);
                const updatedData = {
                    ...response.data.data,
                    refferenceLink: modifiedReferenceLink
                };
                setReference(updatedData);
                setLoading(false);
                // setReference(response.data);
                // setLoading(false);
                // console.log(reference)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const modifyReferenceLinkForEnvironment = (referenceLink) => {
        if (window.location.hostname.includes('procloudex.testmedici.com')) {
            const modifyOrigin = window.location.origin.replace("pro", "")
            return modifyOrigin + referenceLink.substring('https://cloudexcrypto.com'.length);
        } else {
            const modifyOrigin = window.location.origin.replace("pro.", "")
            return modifyOrigin + referenceLink.substring('https://cloudexcrypto.com'.length);
        }
    };

    useEffect(() => {
        getReferenceLink();
    }, []);

    return (
        <>
            <Helmet>
                <title>Cloudex Crypto - {t("reference")}</title>
            </Helmet>
            <Header />
            {
                loading ?
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        <BounceLoader
                            color="#36d7b7"
                            loading={loading}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="card-wrap shadow-sm py-4">
                                    <div className='row justify-content-center'>
                                        <div className='col-xl-9 col-lg-10 col-md-11 col-11'>
                                            <label className="form-label">{t("refferenceCode")}</label>
                                            <>
                                                <CopyToClipboard
                                                    text={reference.referralCode}
                                                    onCopy={() => onCopy(reference.referralCode, "referralCode")}
                                                >

                                                    <div className='d-flex align-items-center'>
                                                        <p className="card-text text-start mb-0">
                                                            {reference.referralCode}
                                                        </p>
                                                        <span className="ms-3">
                                                            {
                                                                copiedMap[`${reference.referralCode}-referralCode`] ?
                                                                    <i
                                                                        className="fa-solid fa-check fa-lg text-success"
                                                                        style={{ cursor: "pointer" }}
                                                                    ></i>
                                                                    :
                                                                    <i
                                                                        className="fa-regular fa-copy fa-lg"
                                                                        style={{ cursor: "pointer" }}
                                                                    ></i>
                                                            }
                                                        </span>
                                                    </div>
                                                </CopyToClipboard>
                                            </>
                                            <div className="card-content text-start mt-4">
                                                <label className="form-label">
                                                    {t("refferenceLink")}
                                                </label>
                                                <>
                                                    <CopyToClipboard
                                                        text={reference.refferenceLink}
                                                        onCopy={() => onCopy(reference.refferenceLink, "refferenceLink")}
                                                    >
                                                        <div className="position-relative">
                                                            <input
                                                                type="text"
                                                                className="form-control py-2 pe-5"
                                                                id="referenceLink"
                                                                name="referenceLink"
                                                                disabled
                                                                value={reference.refferenceLink}
                                                                style={{ color: "#646464", overflow: "auto" }}
                                                            />
                                                            <div className='position-absolute' style={{ right: "15px", top: "8px" }}>
                                                                <span className="ms-2">
                                                                    {
                                                                        copiedMap[`${reference.refferenceLink}-refferenceLink`] ?
                                                                            <i
                                                                                className="fa-solid fa-check fa-lg text-success"
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                            :
                                                                            <i
                                                                                className="fa-regular fa-copy fa-lg"
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </CopyToClipboard>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    );
};

export default Reference;
