import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/actions/actions";
import { useTranslation } from "react-i18next";
import img from "../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { LANGUAGES } from "../constants";
import axios from "axios";
import TwoFACard from "../components/2FA/TwoFACard";

const SignIn = () => {
	const { i18n, t } = useTranslation();
	const [form, setForm] = useState({ email: "", password: "" });
	const [show, setShow] = useState(false);

	const [isLogin, setIsLogin] = useState(true);
	const [isTwoFactor, setIsTwoFactor] = useState(false);

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const [selectedLanguage, setSelectedLanguage] = useState(
		JSON.parse(localStorage.getItem("lang"))
			? JSON.parse(localStorage.getItem("lang"))
			: "en"
	);

	const onChangeLang = (e) => {
		const lang_code = e.code;
		i18n.changeLanguage(lang_code);
		setSelectedLanguage(e.code);
	};

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(selectedLanguage));
	}, []);

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(selectedLanguage));
	}, [selectedLanguage]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const signInHandle = (e) => {
		e.preventDefault();
		try {
			dispatch(loginUser(form.email, form.password, navigate))
				.then(() => {
					const token = localStorage.getItem("jwt_access_token");
					if (token) {
						navigate("/");
						window.location.reload();
					}
				})
				.catch((error) => {
					console.log(error)
					Swal.fire({
						title: t("errorMessage"),
						text: t("wrongEmailPass"),
						icon: "warning",
					});
				});
		} catch (error) {
			Swal.fire({
				title: t("errorMessage"),
				text: t("defaulterrormessage"),
				icon: "warning",
			});
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "none",
			border: "none",
			color: "#fff",
		}),
		menu: (provided, state) => ({
			...provided,
			background: "none",
			color: "#fff",
			width: "150px",
		}),
		option: (provided, state) => ({
			...provided,
			background: state.isFocused ? "#f0f0f0" : "white",
			color: "#000",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "rgb(148 163 184/1)",
		}),
		indicatorSeparator: (provided, state) => ({
			display: "none",
		}),
		dropdownIndicator: (provided, state) => ({
			display: "none",
		}),
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row vh-100">
					<div className="col-lg-6 col-12 d-flex align-items-center justify-content-lg-end pe-lg-5">
						{
							isLogin ?
								<div className="w-50 signin-form">
									<div className="d-flex align-items-center justify-content-between">
										<h3 className="signin-title">{t("signin")}</h3>
										<Select
											className="language-select-box"
											defaultValue={
												LANGUAGES.find((lang) => lang.code === selectedLanguage)
													? LANGUAGES.find((lang) => lang.code === selectedLanguage)
													: LANGUAGES.find((lang) => lang.code === "en")
											}
											// value={LANGUAGES.find((lang) => lang.code === selectedLanguage)}
											onChange={onChangeLang}
											styles={customStyles}
											isSearchable={false}
											options={LANGUAGES}
											getOptionLabel={(option) => (
												<div className="d-flex align-items-center">
													<img
														src={option.flag}
														alt={option.label}
														width="30px"
														className="me-2"
													/>
													{option.label}
												</div>
											)}
										/>
									</div>
									<form onSubmit={signInHandle}>
										<div className="mb-3 mt-5">
											<label htmlFor="email" className="form-label">
												{t("email")}
											</label>
											<input
												type="email"
												name="email"
												placeholder={t("email")}
												className="form-control py-2"
												id="email"
												value={form.email}
												onChange={handleChange}
												required
											/>
										</div>
										<div>
											<label htmlFor="password" className="form-label">
												{t("password")}
											</label>
											<div className="input-group">
												<input
													type={show ? "text" : "password"}
													name="password"
													placeholder={t("password")}
													value={form.password}
													className="form-control rounded py-2"
													id="password"
													onChange={handleChange}
													required
												/>
												<button
													className="btn show-hide-btn"
													type="button"
													id="button-addon2"
													style={{ width: "45px" }}
													onClick={() => setShow(!show)}
												>
													{show ? (
														<i className="fa-regular fa-eye-slash"></i>
													) : (
														<i className="fa-regular fa-eye"></i>
													)}
												</button>
											</div>
										</div>
										<div className="my-4">
											<Link
												className="text-decoration-none forgot-pass-text"
												to="/forgot-password"
											>
												{t("forgotPass")}
											</Link>
										</div>
										<button className="btn btn-secondary-v2 w-100" type="submit">
											{t("signin")}
										</button>
									</form>
								</div>
								:
								isTwoFactor ?
									<TwoFACard />
									:
									null
						}
					</div>
					<div
						className="col-lg-6 d-flex align-items-center ps-lg-5 justify-content-lg-start justify-content-center"
						style={{ backgroundColor: "#100D28" }}
					>
						<img src={img} alt="sign-in-img" width="65%" />
					</div>
				</div>
			</div>
		</>
	);
};

export default SignIn;
