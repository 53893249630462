import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Countdown from "react-countdown";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apiUrls } from '../../auth/services/baseService/UrlConfig';

const TwoFACard = () => {
    const { t } = useTranslation();
    const [values, setValues] = useState(Array(6).fill(""));
    const inputRefs = useRef([]);
    const [isError, setIsError] = useState(false);
    const [isRecoveryError, setIsRecoveryError] = useState(false);
    const [recoveryPage, setRecoveryPage] = useState(false);
    const [recoveryCode, setRecoveryCode] = useState("");
    const [startTime, setStartTime] = useState(Date.now());
    const countdownDuration = 90000;
    const navigate = useNavigate();

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9a-zA-Z]$/.test(value)) {
            // Yeni değeri state'e kaydet
            const newValues = [...values];
            newValues[index] = value;
            setValues(newValues);

            // Bir sonraki inputa geç
            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newValues = [...values];
            if (values[index] === "") {
                // Eğer mevcut input boşsa bir önceki inputa geç
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
            } else {
                // Mevcut inputu sil ve state'i güncelle
                newValues[index] = "";
                setValues(newValues);
            }
        }
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            localStorage.removeItem("countdown_time");
            localStorage.removeItem("temporary_token");
            window.location.href = "/sign-in";
        } else {
            // Render a countdown
            return (
                <div className='badge text-bg-primary py-2 px-3' style={{ fontSize: "15px", fontWeight: 600 }}>
                    {t("remainingtime")}: {minutes}:{seconds}
                </div>
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newValues = values.join('');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${apiUrls.handleSubmit2FA()}${newValues}`,
            headers: {
                'authorization': `Bearer ${localStorage.getItem("temporary_token")}`,
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("jwt_access_token", response.data.token)
                    localStorage.removeItem("temporary_token")

                    window.location.href = "/";
                }
            })
            .catch((error) => {
                console.log("error", error);
                setIsError(true);
            });
    }

    const handleRecoverySubmit = (e) => {
        e.preventDefault();
        console.log(recoveryCode)

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrls.handleRecoverySubmit(),
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem("temporary_token")}`
            },
            data: recoveryCode
        };

        axios.request(config)
            .then((response) => {
                Swal.fire({
                    title: t("successMessage"),
                    html: t("2farecoverycodesuccess"),
                    timer: 4000,
                    timerProgressBar: true,
                }).then(() => {
                    navigate("/")
                });
            })
            .catch((error) => {
                console.log(error);
                setIsRecoveryError(true)
            });
    }

    useEffect(() => {
        const storedTime = localStorage.getItem("countdown_time");
        if (storedTime) {
            setStartTime(parseInt(storedTime, 10));
        } else {
            const newStartTime = Date.now();
            localStorage.setItem("countdown_time", newStartTime);
            setStartTime(newStartTime);
        }
    }, []);

    return (
        <>
            <div className="twofa-container">
                <div className="twofa-card shadow-sm text-center">
                    <img width="86" height="86" src="https://img.icons8.com/dotty/86/sms-token.png" alt="sms-token" className='mb-4' />
                    <h3 className='mb-3'>{t("2farequired")}</h3>
                    <p className="mb-0 px-4">
                        {t("2farequiredtext")}
                    </p>
                    <div className="mt-4">
                        <Countdown date={startTime + countdownDuration}
                            renderer={renderer} />
                    </div>
                    {
                        !recoveryPage ?
                            // PIN KODU
                            <div className='mt-4'>
                                <form className='mt-5' onSubmit={handleSubmit}>
                                    <div className='d-flex justify-content-center' style={{ gap: "10px" }}>
                                        {values.map((value, index) => (
                                            <input
                                                key={index}
                                                ref={el => inputRefs.current[index] = el}
                                                type="text"
                                                maxLength={1}
                                                value={value}
                                                onChange={(e) => handleChange(e, index)}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                className={`twofa-input ${isError && "error"}`}
                                            />
                                        ))}
                                    </div>
                                    {
                                        isError && <p className='mb-0 mt-3 text-danger'>{t("2fainvalidpin")}</p>
                                    }
                                    <button className='mt-5 btn btn-primary-v2 px-5 py-2' type='submit'>{t("signin")}</button>
                                </form>
                            </div>
                            :
                            // KURTARMA KODU
                            <>
                                <form className='mt-5' onSubmit={handleRecoverySubmit}>
                                    <div>
                                        <label htmlFor="recovery" className='mb-1'>{t("recoverycode")}</label>
                                        <input
                                            type="text"
                                            id='recovery'
                                            value={recoveryCode}
                                            className='form-control'
                                            onChange={(e) => setRecoveryCode(e.target.value)}
                                        />
                                    </div>
                                    {
                                        isRecoveryError && <p className='mb-0 mt-3 text-danger'>{t("2fainvalidpin")}</p>
                                    }
                                    <button className='mt-5 btn btn-primary-v2 px-5 py-2' type='submit'>{t("signin")}</button>
                                </form>
                            </>
                    }
                    <div className='mt-4'>
                        {
                            !recoveryPage ?
                                <div>
                                    <div className='text-decoration-underline text-primary mt-1' style={{ cursor: "pointer" }} onClick={() => setRecoveryPage(true)}>{t("access2facode")}</div>
                                </div>
                                :
                                <div>
                                    <div className='text-decoration-underline text-primary mt-1' style={{ cursor: "pointer" }} onClick={() => setRecoveryPage(false)}>{t("loginwith2facode")}</div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default TwoFACard