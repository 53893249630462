import React from "react";
import { useTranslation } from "react-i18next";

const WithdrawalNavs = () => {
	const { i18n, t } = useTranslation();
	return (
		<ul className="nav nav-tabs withdraw-navs" id="myTab" role="tablist">
			<li className="nav-item">
				<button
					className="nav-link active"
					data-bs-toggle="pill"
					data-bs-target="#home-tab-pane"
					type="button"
					role="tab"
					aria-controls="home-tab-pane"
					aria-selected="true"
				>
					{t("withdrawal")}
				</button>
			</li>
			<li className="nav-item">
				<button
					className="nav-link"
					data-bs-toggle="pill"
					data-bs-target="#profile-tab-pane"
					type="button"
					role="tab"
					aria-controls="profile-tab-pane"
					aria-selected="false"
				>
					{t("cryptocurrencyWithdrawal")}
				</button>
			</li>
		</ul>
	);
};

export default WithdrawalNavs;
