// actions.js
import axios from "axios";
import jwtServiceConfig from "../../auth/services/jwtService/jwtServiceConfig";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const loginRequest = () => ({
	type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
	type: LOGIN_SUCCESS,
	payload: user,
});

export const loginFailure = (error) => ({
	type: LOGIN_FAILURE,
	payload: error,
});

let url;
if (window.location.origin.includes("localhost")) {
	url = "https://cloudexcloudex.testmedici.com";
} else {
	url = window.location.origin.replace("pro", "cloudex");
}

let Loginurl;
if (window.location.origin.includes("localhost")) {
	Loginurl = "https://procloudex.testmedici.com";
} else {
	Loginurl = window.location.origin;
}

export const loginUser = (email, password, navigate) => {
	return (dispatch) => {
		dispatch(loginRequest());

		return new Promise((resolve, reject) => {
			axios
				.post(
					Loginurl +
					jwtServiceConfig.login,
					{
						email: email,
						password: password
					}
				)
				.then((response) => {
					try {
						if (!response.data.data.isTwoFactorEnabled) {
							if (response.data.success) {
								setSession(response.data.data.token);
								axios
									.get(url + jwtServiceConfig.apiSirix + "/GetMe")
									.then((res) => {
										if (res.data.UserId) {
											dispatch(loginSuccess());
											resolve(res.data);
											setSessionWithBearer();
										}
									});
							}
						} else {
							setTemporarySession(response.data.data.token)
							navigate("/2fa");
						}
					} catch (error) {
						dispatch(loginFailure());
						reject(new Error("Failed to login with token."));
					}
				})
				.catch((err) => {
					dispatch(loginFailure());
					reject(new Error("Failed to login with token."));
				});
		});
	};
};

export const getMe = () => {
	return new Promise((resolve, reject) => {
		axios.get(jwtServiceConfig.apiSirix + "/GetMe").then((response) => {
			if (response.data.userId) {
				resolve(response.data);
				this.emit("onLogin", response.data);
			} else {
				reject(response.data.error);
			}
		});
	});
};

export const setSession = (access_token) => {
	if (access_token) {
		localStorage.setItem("jwt_access_token", access_token);
		axios.defaults.headers.common.Authorization = `${access_token}`;
	} else {
		localStorage.removeItem("jwt_access_token");
		delete axios.defaults.headers.common.Authorization;
	}
};

export const setTemporarySession = (token) => {
	if (token) {
		localStorage.setItem("temporary_token", token);
		axios.defaults.headers.common.Authorization = `${token}`;
	} else {
		localStorage.removeItem("temporary_token");
		delete axios.defaults.headers.common.Authorization;
	}
};

export const setSessionWithBearer = () => {
	let token = localStorage.getItem("jwt_access_token");
	axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};
