import React, { Children, useEffect, useState } from "react";
import FileId from "../components/Documents/FileId";
import FileLocation from "../components/Documents/FileLocation";
import FileBankReceipt from "../components/Documents/FileBankReceipt";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { apiUrls } from "../auth/services/baseService/UrlConfig";
import BounceLoader from "react-spinners/BounceLoader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const FilesLayout = () => {
	const { i18n, t } = useTranslation();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [fileDetails, setFileDetails] = useState({
		idFile: null,
		bankFile: null,
		locationFile: null,
	});
	const [uploadedFiles, setUploadedFiles] = useState({
		idFile: false,
		bankFile: false,
		locationFile: false,
	});
	const navigate = useNavigate()

	const getDocuments = () => {
		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: apiUrls.myDocuments(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
			},
		};

		axios
			.request(config)
			.then((response) => {
				if (response.data.data === null) {
					localStorage.removeItem("jwt_access_token");
					localStorage.removeItem("fullname");
					localStorage.removeItem("userid");
					navigate("/sign-in");
					window.location.reload();
				}
				else {
					const documents = response.data.data;
					setData(documents);
					setUploadedFiles({
						idFile: documents.some(doc => doc.fileName === "identity"),
						bankFile: documents.some(doc => doc.fileName === "bank"),
						locationFile: documents.some(doc => doc.fileName === "addressproof"),
					});

					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const handleFileChange = (type, file) => {
		setFileDetails((prevDetails) => ({ ...prevDetails, [type]: file }));
	};

	const handleSubmitAll = () => {
		const { idFile, bankFile, locationFile } = fileDetails;
		const { idFile: isIdUploaded, bankFile: isBankUploaded, locationFile: isLocationUploaded } = uploadedFiles;

		// Kullanıcının yüklediği belgeler
		const anyFileUploaded = isIdUploaded || isBankUploaded || isLocationUploaded;

		// Henüz hiç belge yüklenmemişse, tüm belgelerin yüklenmesini zorunlu kıl
		if (!anyFileUploaded) {
			if (idFile && bankFile && locationFile) {
				Swal.fire({
					title: t("areYouSureTitle"),
					text: t("areYouSureText"),
					icon: "question",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: t("confirm"),
					cancelButtonText: t("cancel"),
				}).then((result) => {
					if (result.isConfirmed) {
						const uploadFile = (url, file) => {
							const formData = new FormData();
							formData.append("file", file);
							return axios.post(url, formData, {
								headers: {
									"Content-Type": "multipart/form-data",
									Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
								},
							});
						};

						const requests = [];
						if (idFile) requests.push(uploadFile(apiUrls.identityPost(), idFile));
						if (bankFile) requests.push(uploadFile(apiUrls.bankPost(), bankFile));
						if (locationFile) requests.push(uploadFile(apiUrls.locationPost(), locationFile));

						Promise.all(requests)
							.then((responses) => {
								let hasError = false;
								responses.forEach((response) => {
									if (response.data.errors.length > 0) hasError = true;
								});

								if (hasError) {
									Swal.fire({
										title: t("errorMessage"),
										html: t("docuploaderror"),
										timer: 2000,
										timerProgressBar: true,
									}).then(() => {
										localStorage.removeItem("jwt_access_token");
										localStorage.removeItem("fullname");
										localStorage.removeItem("userid");
										navigate("/sign-in");
										window.location.reload();
									});
								} else {
									Swal.fire({
										title: t("successMessage"),
										text: t("successMessageText"),
										icon: "success",
									}).then(() => {
										window.location.reload();
									});
								}
							})
							.catch((error) => {
								console.error("Error uploading file:", error);
								Swal.fire({
									title: t("errorMessage"),
									text: error,
									icon: "warning",
								});
							});
					}
				});
			} else {
				Swal.fire({
					title: t("warning"),
					text: t("docensure"),
					icon: "warning"
				});
			}
		} else {
			// Eğer bazı belgeler yüklenmişse, yalnızca eksik olanları yüklemeye izin ver
			const missingFiles = [];
			if (idFile && !isIdUploaded) missingFiles.push("identity");
			if (bankFile && !isBankUploaded) missingFiles.push("bank");
			if (locationFile && !isLocationUploaded) missingFiles.push("addressproof");
			if (data.length === 1) {
				if (missingFiles.length > 1) {
					Swal.fire({
						title: t("areYouSureTitle"),
						text: t("areYouSureText"),
						icon: "question",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: t("confirm"),
						cancelButtonText: t("cancel"),
					}).then((result) => {
						if (result.isConfirmed) {
							const uploadFile = (url, file) => {
								const formData = new FormData();
								formData.append("file", file);
								return axios.post(url, formData, {
									headers: {
										"Content-Type": "multipart/form-data",
										Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
									},
								});
							};

							const requests = [];
							if (idFile && !isIdUploaded) requests.push(uploadFile(apiUrls.identityPost(), idFile));
							if (bankFile && !isBankUploaded) requests.push(uploadFile(apiUrls.bankPost(), bankFile));
							if (locationFile && !isLocationUploaded) requests.push(uploadFile(apiUrls.locationPost(), locationFile));

							Promise.all(requests)
								.then((responses) => {
									let hasError = false;
									responses.forEach((response) => {
										if (response.data.errors.length > 0) hasError = true;
									});

									if (hasError) {
										Swal.fire({
											title: t("errorMessage"),
											html: t("docuploaderror"),
											timer: 2000,
											timerProgressBar: true,
										}).then(() => {
											localStorage.removeItem("jwt_access_token");
											localStorage.removeItem("fullname");
											localStorage.removeItem("userid");
											navigate("/sign-in");
											window.location.reload();
										});
									} else {
										Swal.fire({
											title: t("successMessage"),
											text: t("successMessageText"),
											icon: "success",
										}).then(() => {
											window.location.reload();
										});
									}
								})
								.catch((error) => {
									console.error("Error uploading file:", error);
									Swal.fire({
										title: t("errorMessage"),
										text: error,
										icon: "warning",
									});
								});
						}
					});
				}
				if (missingFiles.length < 2) {
					Swal.fire({
						title: t("warning"),
						text: t("docensure"),
						icon: "warning",
					})
				}
			}
			if (data.length === 2) {
				if (missingFiles.length > 0) {
					Swal.fire({
						title: t("areYouSureTitle"),
						text: t("areYouSureText"),
						icon: "question",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: t("confirm"),
						cancelButtonText: t("cancel"),
					}).then((result) => {
						if (result.isConfirmed) {
							const uploadFile = (url, file) => {
								const formData = new FormData();
								formData.append("file", file);
								return axios.post(url, formData, {
									headers: {
										"Content-Type": "multipart/form-data",
										Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
									},
								});
							};

							const requests = [];
							if (idFile && !isIdUploaded) requests.push(uploadFile(apiUrls.identityPost(), idFile));
							if (bankFile && !isBankUploaded) requests.push(uploadFile(apiUrls.bankPost(), bankFile));
							if (locationFile && !isLocationUploaded) requests.push(uploadFile(apiUrls.locationPost(), locationFile));

							Promise.all(requests)
								.then((responses) => {
									let hasError = false;
									responses.forEach((response) => {
										if (response.data.errors.length > 0) hasError = true;
									});

									if (hasError) {
										Swal.fire({
											title: t("errorMessage"),
											html: t("docuploaderror"),
											timer: 2000,
											timerProgressBar: true,
										}).then(() => {
											localStorage.removeItem("jwt_access_token");
											localStorage.removeItem("fullname");
											localStorage.removeItem("userid");
											navigate("/sign-in");
											window.location.reload();
										});
									} else {
										Swal.fire({
											title: t("successMessage"),
											text: t("successMessageText"),
											icon: "success",
										}).then(() => {
											window.location.reload();
										});
									}
								})
								.catch((error) => {
									console.error("Error uploading file:", error);
									Swal.fire({
										title: t("errorMessage"),
										text: error,
										icon: "warning",
									});
								});
						}
					});
				}
				if (missingFiles.length < 1) {
					Swal.fire({
						title: t("warning"),
						text: t("docensure"),
						icon: "warning",
					})
				}
			}
		}
	};

	useEffect(() => {
		getDocuments();
	}, []);

	return (
		<>
			{loading ? (
				<div className="d-flex align-items-center justify-content-center vh-100">
					<BounceLoader
						color="#36d7b7"
						loading={loading}
						size={150}
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			) : (
				<div className="container my-5">
					<div className="card px-lg-4 pb-lg-5 p-md-4 py-4 px-0 border-0 shadow-sm">
						<h2 className="mb-4 ps-3 fw-bold">{t("KYC")}</h2>
						<div
							className="alert alert-warning d-flex align-items-start mb-4 documents-warning"
							role="alert"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2 mt-1"
								viewBox="0 0 16 16"
								role="img"
								aria-label="Warning:"
								width="17px"
							>
								<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
							</svg>
							<div className="ms-2">
								<div
									className="alert-heading mb-2"
									style={{ fontSize: "17px", fontWeight: "600" }}
								>
									{t("documentsAlert")}
								</div>
								<div className="mb-1" style={{ fontSize: "15px" }}>
									* {t("documentsItem1")}
								</div>
								<div className="mb-1" style={{ fontSize: "15px" }}>
									* {t("documentsItem2")}
								</div>
								<div className="mb-1" style={{ fontSize: "15px" }}>
									* {t("documentsItem3")}
								</div>
								<div className="mb-1" style={{ fontSize: "15px" }}>
									* {t("documentsItem4")}
								</div>
								<div className="mb-1" style={{ fontSize: "15px" }}>
									* {t("documentsItem5")}
								</div>
								<div className="mb-1" style={{ fontSize: "15px" }}>
									* {t("documentsItem6")}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4">
								<div className="px-3">
									<FileId data={data} onFileChange={(file) => handleFileChange("idFile", file)} isUploaded={uploadedFiles.idFile} />
								</div>
							</div>
							<div className="col-lg-4 mt-lg-0 mt-md-5 mt-5">
								<div className="px-3">
									<FileBankReceipt data={data} onFileChange={(file) => handleFileChange("bankFile", file)} isUploaded={uploadedFiles.bankFile} />
								</div>
							</div>
							<div className="col-lg-4 mt-lg-0 mt-md-5 mt-5">
								<div className="px-3">
									<FileLocation data={data} onFileChange={(file) => handleFileChange("locationFile", file)} isUploaded={uploadedFiles.locationFile} />
								</div>
							</div>
							<div className="px-4 mt-3">
								<button
									className="btn btn-secondary-v2 w-100 mt-3"
									onClick={handleSubmitAll}
								>
									{t("upload")}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default FilesLayout;
