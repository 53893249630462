// reducers.js

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "../actions/actions";

const initialState = {
	loading: false,
	user: null,
	error: "",
};

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_REQUEST:
			return {
				...state,
				loading: true,
				error: "",
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload,
				error: "",
			};
		case LOGIN_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
